import 'twin.macro';

import { motion, useCycle } from 'framer-motion';
import React, { useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useLockBodyScroll } from 'react-use';
import tw from 'twin.macro';

import { isWorld } from '@/app/api/API-Url';
import {
  deposit_available_for,
  invest_available_for,
  is_my_documents_open,
  is_prepaid_card_open,
  landing_available_for
} from '@/app/availlability_vars';
import { getChannel } from '@/app/store/slices/channel';
import SmallFfinLogo from '@/assets/images/icons/SmallFfinLogo';
import { Caption, MenuToggle, SubTitle } from '@/components';
import { DeviceInfoContext } from '@/contexts/device-info-context';

import Authmodal from '../auth-modal';
import SocialMedias from '../social-medias';
const langs = [
  {
    value: 'rus',
    title: 'Рус'
  },
  {
    value: 'eng',
    title: 'Eng'
  }
];
function MobileHeader({ isHeader }) {
  const [scroll, setScroll] = useState(0);
  const [open, setOpen] = useState(false);
  const channel = useSelector(getChannel);
  const { t } = useTranslation();
  const { height } = useContext(DeviceInfoContext);
  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      transition: {
        type: 'spring',
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: 'circle(1px at 1px 1px)',
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 40
      }
    }
  };
  const [isOpen, toggleOpen] = useCycle(false, true);

  const location = useLocation().pathname;

  useLockBodyScroll(isOpen);
  window.addEventListener('scroll', function () {
    const scrollPosition = window.scrollY;
    setScroll(scrollPosition);
  });

  return (
    <div tw='fixed w-full p-5 z-20 transition-colors' css={[scroll > 100 && tw`bg-secondary shadow-md`]}>
      <div tw='w-full flex items-start justify-between' css={[isHeader ? tw`pl-10` : '']}>
        <a href='/'>
          <SmallFfinLogo />
        </a>
        {/* <Selector items={langs} defaultActiveItem={'rus'} disabled={true} /> */}
      </div>
      {isHeader && (
        <motion.div initial={false} animate={isOpen ? 'open' : 'closed'} custom={height}>
          <motion.div
            tw='absolute top-0 bottom-0 left-0 h-full flex flex-col justify-center w-full bg-secondary overflow-hidden z-20   h-[100vh]'
            variants={sidebar}
          >
            <div tw='flex flex-col items-center justify-between w-full'>
              <div tw='flex flex-col gap-5 w-full items-center'>
                {landing_available_for?.find(item => item === channel) && (
                  <>
                    <span tw='w-full h-[1px] bg-[#444444]' />
                    <SubTitle
                      text={<a href='/'> {t('info_landing.header.main')}</a>}
                      variant={location === '/' && 'bold'}
                    />
                    <span tw='w-full h-[1px] bg-[#444444]' />
                  </>
                )}
                {deposit_available_for?.find(item => item === channel) && (
                  <>
                    <SubTitle
                      text={<a href='/deposit-card-info'> {t('info_landing.header.deposit')}</a>}
                      variant={location === '/deposit-card-info/' && 'bold'}
                    />
                    <span tw='w-full h-[1px] bg-[#444444]' />
                  </>
                )}
                {invest_available_for?.find(item => item === channel) && (
                  <>
                    <SubTitle
                      text={<a href='/invest-card-info'>{t('info_landing.header.invest')}</a>}
                      variant={location === '/invest-card-info/' && 'bold'}
                    />
                    <span tw='w-full h-[1px] bg-[#444444]' />
                  </>
                )}
                {is_prepaid_card_open && (
                  <>
                    <SubTitle
                      text={<a href='/prepaid-card-info'>{t('info_landing.header.prepaid')}</a>}
                      variant={location === '/prepaid-card-info/' && 'bold'}
                    />
                    <span tw='w-full h-[1px] bg-[#444444]' />
                  </>
                )}
                {is_my_documents_open && (
                  <>
                    <SubTitle
                      text={<a href='/my-documents'>{t('info_landing.header.my_documents')}</a>}
                      variant={(location === '/my-documents' || location === '/my-documents-main') && 'bold'}
                    />
                    <span tw='w-full h-[1px] bg-[#444444]' />
                  </>
                )}
                <SubTitle text={<button onClick={() => setOpen(true)}>{t('info_landing.header.status')}</button>} />
                <span tw='w-full h-[1px] bg-[#444444]' />
              </div>

              <div tw='flex flex-col gap-5 py-5'>
                <a href='tel:595' target={'_blank'} rel='noreferrer'>
                  <div>
                    <SubTitle twStyle={tw`text-center`} text={<span href=''>595</span>} />
                    <Caption text={'бесплатный звонок по РК '} twStyle={tw`text-center text-secondary`} />
                  </div>
                </a>
              </div>
              <div tw='flex flex-col gap-5 w-full items-center'>
                <span tw='w-full h-[1px] bg-[#444444]' />
                <SocialMedias />
              </div>
            </div>
          </motion.div>
          <MenuToggle toggle={toggleOpen} />
          <Authmodal authOpen={open} setAuthOpen={setOpen} />
        </motion.div>
      )}
    </div>
  );
}

export default MobileHeader;

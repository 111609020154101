import { split } from 'ramda';
import { useEffect } from 'react';
import { useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

import { setCustomError } from '@/app/store/slices/error';
import { nr_pathnames } from '@/app/utils/constants';

import { usePersonalShortLinkDecodeMutation, useShortLinkDecodeMutation } from './app/api/short-link';
import { deposit_available_for, invest_available_for, landing_available_for } from './app/availlability_vars';
import { getStep } from './app/store/slices/app';
import { setAuth } from './app/store/slices/auth';
import { getChannel, setChannel } from './app/store/slices/channel';
import { getCustomErrors, getErrors } from './app/store/slices/error';
import useCheckStatus from './helper/hooks/useCheckStatus';
import useCheckStatusPrepaid from './helper/hooks/useCheckStatusPrepaid';
import { useNavigateToTheStep } from './helper/hooks/useNavigateToTheStep';
import { RequireAuth } from './helper/RequireAuth';
import PlDecodeErrorPage from './pages/decode-error-page';
import MyDocumentsMainProfile from './pages/my-documents/my-documents-profile';
import MyDocumentsAuth from './pages/my-documents-auth';
import Landinglayout from './views/layouts/landing-layout';

if (import.meta.env.PROD) {
  console.log = () => {};
}

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const step = useSelector(getStep);
  const error = useSelector(getErrors);
  const customError = useSelector(getCustomErrors);
  useErrorHandler(error);

  const token = searchParams.get('token');
  const channel = searchParams.get('channel');
  const storeChannel = useSelector(getChannel);

  const depositAllowed = deposit_available_for.find(item => item === storeChannel);
  const investAllowed = invest_available_for.find(item => item === storeChannel);
  const landingAllowed = landing_available_for.find(item => item === storeChannel);

  const [checkStatus] = useCheckStatus();
  const [checkStatusPrepaid] = useCheckStatusPrepaid();
  const [navigateToNewStep] = useNavigateToTheStep();

  const [fetchShortLinkDecode, { data: shortLinkDecodeData, error: shortLinkDecodeError }] =
    useShortLinkDecodeMutation();

  const [
    fetchPersonalShortLinkDecode,
    {
      data: personalShortLinkDecodeData,
      error: personalShortLinkDecodeError,
      isSuccess: personalShortLinkDecodeSuccess
    }
  ] = usePersonalShortLinkDecodeMutation();

  useEffect(() => {
    if (token?.length > 0) {
      setIsLoading(true);
      dispatch(setAuth({ access: token, refresh: token }));
      checkStatus();
      setIsLoading(false);
    }
  }, [token, dispatch, checkStatus]);

  useEffect(() => {
    if (channel?.length > 0) {
      dispatch(setChannel(channel));
    }
  }, [channel, dispatch]);

  useEffect(() => {
    if (
      customError?.code &&
      customError?.code !== 'AUTHORIZED_PERSONAL_LINK' &&
      customError?.code !== 'INVALID_PERSONAL_LINK'
    ) {
      navigate('/error');
    }
  }, [customError?.code, navigate]);

  useEffect(() => {
    if (split('/', location.pathname)[1] === 's') {
      fetchShortLinkDecode({ token: split('/', location.pathname)[2] })
        .unwrap()
        .then(data => {
          dispatch(setAuth({ access: data?.data?.access, refresh: data?.data?.refresh }));
          switch (data?.data?.service) {
            case 'MY_DOCUMENTS_DEPOSIT_CARD':
              navigate('my-documents-main');
              break;
            case 'MY_DOCUMENTS_INVEST_CARD':
              navigate('my-documents-main');
              break;
            case 'ORDER_PREPAID':
              checkStatusPrepaid();
              break;
            default:
              checkStatus();
              break;
          }
        });
    }
  }, [location.pathname, dispatch, checkStatus, fetchShortLinkDecode]);

  useEffect(() => {
    if (split('/', location.pathname)[1] === 'pl') {
      fetchPersonalShortLinkDecode({ token: split('/', location.pathname)[2] })
        .unwrap()
        .then(() => {
          navigate('/');
        })
        .catch(err => {
          // console.log('error', err);
          dispatch(setCustomError(err?.data?.error));
          navigate('/err');
        });
    }
  }, [location.pathname, dispatch, checkStatus, fetchPersonalShortLinkDecode]);

  useEffect(() => {
    if (String(shortLinkDecodeError?.data?.error.code).toLowerCase() === 'invalid_short_link') {
      dispatch(setCustomError(shortLinkDecodeError?.data?.error));
      navigate('/error');
    }
  }, [shortLinkDecodeError, dispatch]);

  useEffect(() => {
    step && navigateToNewStep();
  }, [step]);

  if (isLoading) {
    return (
      <div tw='w-full flex justify-center h-[95vh] items-center'>
        <BeatLoader color='rgb(42, 134, 64)' />
      </div>
    );
  }

  // console.log('sdad', investAllowed || depositAllowed || landingAllowed);
  return (
    <Routes>
      <Route element={<Landinglayout isHeader={investAllowed || depositAllowed || landingAllowed} />}>
        <Route index element={<MyDocumentsAuth />} />
        <Route
          path={nr_pathnames.my_documents_main}
          element={
            <RequireAuth>
              <MyDocumentsMainProfile />
            </RequireAuth>
          }
        />
      </Route>
      <Route path={nr_pathnames.decode_err} element={<PlDecodeErrorPage />} />
    </Routes>
  );
}
export default App;

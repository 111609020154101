import 'twin.macro';

import * as R from 'ramda';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { useDebounce, usePreviousDistinct } from 'react-use';

import { Input2 } from '@/components';

import { YandexMap } from './yandex-map';

export const dataToAddressOptions = data => R.compose(R.map(R.prop('displayName')))(data);

export const fetchGeoCode = async (ymaps, address, type) => {
  let addressTemp = {};
  const result = await ymaps.geocode(type === 'revert' ? address : `${address}`);

  const coords = result.geoObjects.get(0).geometry.getCoordinates();
  const properties = result.geoObjects.get(0).properties.getAll();
  const yAddress = properties.metaDataProperty.GeocoderMetaData.Address.Components;

  if (type === 'revert') {
    addressTemp.address = result.geoObjects.get(0).getAddressLine();
  }

  yAddress.forEach(item => {
    if (item.kind === 'locality') {
      addressTemp.city = item.name;
    }
    if (item.kind === 'street') {
      addressTemp.street = item.name;
    }
    if (item.kind === 'district') {
      addressTemp.district = item.name;
    }
    if (item.kind === 'house') {
      addressTemp.building = item.name;
    }
  });
  return { ...addressTemp, coords };
};

export const fetchSuggestions = async (ymaps, address) => {
  const result = await ymaps.suggest(`${address}`, { results: 5 });
  return dataToAddressOptions(result);
};

function DeliveryForm({ control, reset, clearErrors, watch, setValue, setError, isValid, city, setDeliveryType }) {
  //states
  const [revert, setRevert] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [debouncedAddress, setDebouncedAddress] = React.useState('');
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [map, setMap] = useState(null);

  const prevCity = usePreviousDistinct(city?.uuid, (prev, next) => (prev && prev) === next);
  const address = watch('address');

  const [, cancel] = useDebounce(
    () => {
      setDebouncedAddress(address);
    },
    750,
    [address]
  );

  const [] = useDebounce(
    () => {
      if (map && debouncedAddress && prevCity !== city?.uuid && selectedAddress !== address) {
        fetchSuggestions(map, `город ${city?.name}, ${debouncedAddress}`).then(suggestions => {
          setAddresses(suggestions);
        });
      }
    },
    5000,
    [debouncedAddress, city, prevCity, selectedAddress, address, map]
  );

  const lat = watch('lat');
  const prevLat = usePreviousDistinct(lat, (prev, next) => (prev && prev) === next);
  const lon = watch('lon');
  const prevLon = usePreviousDistinct(lon, (prev, next) => (prev && prev) === next);

  const getMap = useCallback(ymaps => {
    setMap(ymaps);
  }, []);

  useEffect(() => {
    setDeliveryType('DELIVERY');
  }, [setDeliveryType]);

  useEffect(() => {
    if (selectedAddress === address) {
      setAddresses([]);
    }
  }, [selectedAddress, address]);

  useEffect(() => {
    if (prevCity !== city?.uuid) {
      setValue('address', '');
      setValue('street', '');
      setValue('building', '');
      setValue('flat', '');
      setValue('city', city?.uuid, { shouldValidate: true });
    }
  }, [city?.uuid, prevCity, setValue]);

  useEffect(() => {
    if (map && selectedAddress) {
      fetchGeoCode(map, selectedAddress).then(address => {
        if (address) {
          if (!address.building) {
            setError('address', { type: 'manual', message: 'Укажите номер дома' });
          } else {
            clearErrors('address');
          }
          setValue('street', address.district ? address.district + ' ' : '' + address.street, { shouldValidate: true });
          setValue('building', address.building, { shouldValidate: true });
          setValue('lat', address.coords[0], { shouldValidate: true });
          setValue('lon', address.coords[1], { shouldValidate: true });
        }
        setAddresses([]);
      });
    }
  }, [setValue, selectedAddress, city, map]);

  // useEffect(() => {
  //   if (map && debouncedAddress && prevCity !== city?.uuid && selectedAddress !== address) {
  //     // fetchSuggestions(map, `город ${city?.name}, ${debouncedAddress}`).then(suggestions => {
  //     //   setAddresses(suggestions);
  //     // });
  //   }
  // }, [debouncedAddress, city, prevCity, selectedAddress, address, map]);

  useEffect(() => {
    if (lat && lon && lat !== prevLat && lon !== prevLon && revert) {
      fetchGeoCode(map, [lat, lon], 'revert').then(address => {
        if (address) {
          setValue('address', address.address);
          setSelectedAddress(address.address);
        }
      });
    }
  }, [lat, prevLon, lon, prevLat, setValue, map, revert]);

  useEffect(() => {
    if (map && prevCity !== city?.uuid && !address) {
      fetchGeoCode(map, city?.name).then(address => {
        if (address) {
          setValue('lat', address.coords[0]);
          setValue('lon', address.coords[1]);
        }
      });
    }
  }, [city, prevCity, setValue, map, address]);

  const handleSetAddress = useCallback(
    address => {
      setValue('address', address);
      setSelectedAddress(address);
      setRevert(false);
    },
    [setValue]
  );

  const geoCoords = useMemo(() => {
    if (lat && lon) {
      return [lat, lon];
    }
  }, [lat, lon]);

  const formSetCoords = useCallback(
    coords => {
      setValue('lat', coords[0]);
      setValue('lon', coords[1]);
      setRevert(true);
    },
    [setValue]
  );

  return (
    <>
      <div tw='max-w-[416px] mx-auto '>
        <form noValidate tw='text-left mb-[24px] mt-[24px]'>
          <div tw='flex flex-col gap-5 mb-10'>
            {city && (
              <>
                <div tw='relative'>
                  <Input2
                    name='address'
                    label='Введите адрес'
                    placeholder='Микрорайон, Улица, дом'
                    control={control}
                    isRequired={true}
                  />
                  {addresses?.length > 0 && (
                    <div tw='absolute top-[4.6rem] left-0 right-0 z-50 bg-secondary pb-2'>
                      {addresses?.map((address, i) => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                        <div
                          key={i}
                          onClick={() => {
                            handleSetAddress(address);
                          }}
                          tw='flex items-center justify-between w-full px-4 py-3 mt-1 text-sm text-primary bg-secondary border border-gray-300 rounded-md cursor-pointer hover:bg-primary hover:text-primary'
                        >
                          <span>{address}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <YandexMap getCoords={getMap} geoCoords={geoCoords} formSetCoords={formSetCoords} />
                <div tw='flex gap-3'>
                  <Input2
                    label='Введите квартиру'
                    name='flat'
                    placeholder='Квартира/Офис'
                    control={control}
                    rules={{ required: { value: true, message: 'Это поле не может быть пустым' } }}
                    clearErrors={''}
                    isRequired={false}
                  />
                  <Input2
                    label='Введите подъезд'
                    name='entrance'
                    placeholder='Подъезд'
                    control={control}
                    rules={{ required: { value: true, message: 'Это поле не может быть пустым' } }}
                    clearErrors={''}
                  />
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default DeliveryForm;

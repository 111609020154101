import 'twin.macro';

import { Ring } from '@uiball/loaders';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { usePickUpAdressesQuery } from '@/app/api/delivery';
import { Radio } from '@/components';

const initialParams = {
  limit: null,
  offset: null,
  city: undefined
};

const addressesToString = data => {
  let array = [];

  if (data.length) {
    data.map((item, idx) => {
      let pickUpAddress = '';
      pickUpAddress = 'г.' + item.city + ', ул. ' + item.street + ', ';
      if (item.corpus) {
        pickUpAddress += `${item.corpus}-й корпус`;
      }
      if (item.entrance) {
        pickUpAddress += `${item.entrance}-й подъезд,`;
      }
      if (item.flat) {
        pickUpAddress += `кв ${item.flat}, `;
      }
      if (item.building) {
        pickUpAddress += `д. ${item.building}`;
      }
      array.push({ title: pickUpAddress, value: item.id });
    });
  }
  return array;
};

function PickupForm({ control, setValue, getValues, watch, city, setDeliveryType, twStyle, rbStyle }) {
  const [queryParams, setQueryParams] = useState(initialParams);
  const [pickUpAddressState, setPickUpAddressState] = useState('');

  // const pickUpCity = watch('pickUpCities');
  const pickedAddress = watch('addressRadio');

  const { data: pickUpAdresses, isLoading: pickUpAdressesLoading } = usePickUpAdressesQuery(queryParams, {
    skip: !queryParams?.city
  });
  // const prevPickUpCity = usePreviousDistinct(pickUpCity, (prev, next) => prev === next);

  useEffect(() => {
    const pickUpAdressesString = addressesToString(pickUpAdresses?.data?.results || []);
    setPickUpAddressState(pickUpAdressesString);
    return () => {
      setPickUpAddressState([]);
    };
  }, [pickUpAdresses]);

  useEffect(() => {
    setDeliveryType('PICKUP');
  }, [setDeliveryType]);

  useEffect(() => {
    if (pickUpAddressState.length > 0) {
      setValue('addressRadio', pickUpAddressState[0]?.value, { shouldValidate: true });
    }
  }, [pickUpAddressState]);

  // useEffect(() => {
  //   if (city) {
  //     setQueryParams(queryParams => ({ ...queryParams, city: city }));
  //   }
  // }, [pickUpCity, prevPickUpCity]);
  useEffect(() => {
    setQueryParams(queryParams => ({ ...queryParams, city: city }));
  }, [city]);

  return (
    <>
      <div>
        <form noValidate tw='text-left mb-[24px] '>
          <div>
            <div tw='flex flex-col gap-5 mb-12'>
              {city &&
                (pickUpAdressesLoading ? (
                  <div tw='flex justify-center'>
                    <Ring color='#4f9d3a' />
                  </div>
                ) : (
                  <div>
                    <Radio
                      twStyle={twStyle}
                      rbStyle={rbStyle}
                      name='addressRadio'
                      control={control}
                      options={pickUpAddressState || []}
                    />
                  </div>
                ))}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default PickupForm;

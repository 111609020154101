import 'twin.macro';

import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from '../footer';
import LandingHeader from '../info-landing/landing-header';

function Landinglayout({ isHeader }) {
  return (
    <div tw='bg-secondary'>
      <div tw='max-w-[1000px] mx-auto 2xl:p-10 xl:p-10 sm:p-5 flex flex-col sm:gap-32 gap-20 mb-32'>
        <LandingHeader isHeader={isHeader} />
      </div>
      <div tw='max-w-[1000px] mx-auto 2xl:p-10 xl:p-10 sm:p-5 p-5 flex flex-col sm:gap-32 gap-20 mb-32'>
        <Outlet />
      </div>

      <Footer tw='mt-32' />
    </div>
  );
}

export default Landinglayout;

import 'react-datepicker/dist/react-datepicker.css';

import { AnimatePresence, motion } from 'framer-motion';
import jwt_decode from 'jwt-decode';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import tw from 'twin.macro';

import { useCreateDocumentMutation, useGetUserInfoQuery } from '@/app/api/my-documents';
import { getAccessToken } from '@/app/store/slices/auth';
import BigCheckIcon from '@/assets/images/icons/BigCheckIcon';
import BigExclamationIcon from '@/assets/images/icons/BigExclamationIcon';
import { Button, Modal, SubTitle } from '@/components';
import { DeviceInfoContext } from '@/contexts/device-info-context';
import { ThemeContext } from '@/contexts/theme-context';
import MyDocumentsHeader from '@/views/headers/my-documents-header';
import Tabs from '@/views/tabs';

import DepositCardDocuments from './deposit-card/deposit-card-documents';
import PrepaidCardDocuments from './prepaid-card/prepaid-card-documents';

// const sortPans = array => {
//   const sortedArray = [];

//   array?.reduce((acc, current) => {
//     if (
//       acc !== current.masked_card_number &&
//       !sortedArray?.find(i => i.masked_card_number === current.masked_card_number)
//     ) {
//       sortedArray.push(current);
//       return current.masked_card_number;
//     } else {
//       return acc;
//     }
//   }, 0);
// };

const short_descriptions = {
  deposit: { value: 'DC', type: 'DEPOSIT' },
  invest: { value: 'IC', type: 'INVEST' },
  we_multi_currency: { value: 'WEMCC', type: 'DEPOSIT' },
  multi_currency: { value: 'MCC', type: 'DEPOSIT' },
  we_deposit: { value: 'WEDC', type: 'DEPOSIT' },
  broker: { value: 'BC', type: 'INVEST' }
};
const tabs = [
  { title: 'Депозитная карта', value: short_descriptions.deposit.value, type: short_descriptions.deposit.type },
  { title: 'Карта инвестора', value: short_descriptions.invest.value, type: short_descriptions.invest.type },
  { title: 'Предоплаченные карты', value: 'PREPAID_CARD' },
  {
    title: 'World Elite Депозитная карта',
    value: short_descriptions.we_deposit.value,
    type: short_descriptions.we_deposit.type
  },
  {
    title: 'World Elite Мультивалютная карта',
    value: short_descriptions.we_multi_currency.value,
    type: short_descriptions.we_multi_currency.type
  },
  {
    title: 'Мультивалютная карта',
    value: short_descriptions.multi_currency.value,
    type: short_descriptions.multi_currency.type
  },
  { title: 'Карта Брокера', value: short_descriptions.broker.value, type: short_descriptions.broker.type }
];
const handleCreateTabs = data => {
  const newTabs = [];
  const uniqueDescriptions = new Set();
  const filteredData = data?.accounts?.filter(item => {
    if (!uniqueDescriptions.has(item.short_description)) {
      uniqueDescriptions.add(item.short_description);
      return true;
    }
    return false;
  });

  filteredData?.forEach(item =>
    tabs.find(tab => {
      if (tab.value === item.short_description) {
        tab.product_code = item.product_code;
        newTabs.push(tab);
      }
    })
  );
  data?.has_prepaid && newTabs.push({ title: 'Предоплаченные карты', value: 'PREPAID_CARD' });
  return newTabs;
};

const handleFilterDocuemnts = (data, type) => data?.find(item => item[0]?.card_product === type);

function MyDocumentsMainProfile() {
  let [searchParams, setSearchParams] = useSearchParams();
  const { theme, setTheme } = useContext(ThemeContext);
  const { isMobile } = useContext(DeviceInfoContext);
  const token = useSelector(getAccessToken);
  const [activeTab, setActiveTab] = useState('deposit');
  const {
    data: userInfo,
    refetch: userInfoRefetch,
    isLoading: userInfoIsLoading,
    isFetching: userInfoRefetching
  } = useGetUserInfoQuery();
  const [open, setOpen] = useState(false);
  const [userCards, setUserCards] = useState(null);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(false);

  const location = useLocation();
  const getDefaultTab = () => {
    if (searchParams.get('tab')) {
      const firstTab = tabs.find(tab => tab.value === searchParams.get('tab'));
      return firstTab ? firstTab : handleCreateTabs(userInfo?.data)[0];
    } else {
      return handleCreateTabs(userInfo?.data)[0];
    }
  };

  // const { data: userAccounts, refetch: userAccountRefetch } = useGetUserAccountsQuery();
  // const { data: userDocuments, refetch: userDocumentsRefetch } = useGetUserDocumentsQuery();

  const [
    fetchCreateDocument,
    { isLoading: documentsLoading, isSuccess: createDocumentSuccess, error: createDocumentError }
  ] = useCreateDocumentMutation();

  const userLastName = userInfo?.data?.full_name?.split(' ')[0];
  const userName = userInfo?.data?.full_name?.split(' ')[1].split('')[0] + '.';

  const depositAccounts = userInfo?.data?.accounts?.filter(
    item => item?.short_description === short_descriptions.deposit.value
  );
  const investAccounts = userInfo?.data?.accounts?.filter(
    item => item?.short_description === short_descriptions.invest.value
  );
  const weDepositAccounts = userInfo?.data?.accounts?.filter(
    item => item?.short_description === short_descriptions.we_deposit.value
  );
  const weMultiCurrencyAccounts = userInfo?.data?.accounts?.filter(
    item => item?.short_description === short_descriptions.we_multi_currency.value
  );
  const multiCurrencyAccounts = userInfo?.data?.accounts?.filter(
    item => item?.short_description === short_descriptions.multi_currency.value
  );
  const brokerAccounts = userInfo?.data?.accounts?.filter(
    item => item?.short_description === short_descriptions.broker.value
  );

  // const depositDocuments = handleFilterDocuemnts(userDocuments?.data, 'DEPOSIT');
  // const investDocuments = handleFilterDocuemnts(userDocuments?.data, 'INVEST');

  const mobile_phone = jwt_decode(token).mobile_phone;

  const handleDownloadDocument = (docUrl, token) => {
    //docUrl - watch('documents')
    fetch(`${docUrl}`, {
      headers: { Authorization: `JWT ${token}` }
    })
      .then(res => {
        if (res.ok) {
          return res.blob();
        } else {
          throw new Error('Error');
        }
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `APPLICANT_AGREEMENT_STATEMENT_MERGED.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setDownloadIsLoading(false);
        setDownloadSuccess(true);
      })
      .catch(error => {
        setDownloadSuccess(false);
        setDownloadIsLoading(false);
        setDownloadError(error);
      });
  };

  const handleResetAllStates = () => {
    setOpen(false);
    setDownloadSuccess(false);
    setDownloadIsLoading(false);
    setDownloadError(null);
  };

  useEffect(() => {
    setUserCards(handleCreateTabs(userInfo?.data));
  }, [userInfo]);

  useEffect(() => {
    setDownloadIsLoading(documentsLoading);
  }, [documentsLoading]);

  useEffect(() => {
    setDownloadSuccess(createDocumentSuccess);
  }, [createDocumentSuccess]);

  useEffect(() => {
    setDownloadError(createDocumentError);
  }, [createDocumentError]);

  useEffect(() => {
    // userDocumentsRefetch();
    userInfoRefetch();
    setUserCards(null);
  }, [location]);

  useEffect(() => {
    setTheme('dark');
  }, [setTheme]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (userInfoIsLoading || userInfoRefetching) {
    return (
      <div tw='flex justify-center'>
        <ClipLoader color='green' />
      </div>
    );
  }

  return (
    <AnimatePresence>
      <motion.div
        tw='flex flex-col gap-10 relative overflow-hidden'
        initial={{ opacity: 0 }}
        animate={userInfoRefetching || userInfoIsLoading ? { opacity: 0 } : { opacity: 1 }}
      >
        <MyDocumentsHeader
          isMobile={isMobile}
          mobile_phone={mobile_phone}
          userLastName={userLastName}
          userName={userName}
        />
        <Tabs
          activeTab={activeTab}
          defaultActiveTab={getDefaultTab()}
          setActiveTab={setActiveTab}
          tabs={handleCreateTabs(userInfo?.data)}
        />
        {/* -204.3% */}
        {/* -102.2% */}

        <motion.div
          tw='flex w-full'
          animate={[
            activeTab === short_descriptions.deposit.value && { transform: 'translateX(-0%)' },
            activeTab === short_descriptions.invest.value && { transform: 'translateX(-100%)' },
            activeTab === 'PREPAID_CARD' && { transform: 'translateX(-200%)' },
            activeTab === short_descriptions.we_deposit.value && { transform: 'translateX(-300%)' },
            activeTab === short_descriptions.we_multi_currency.value && { transform: 'translateX(-400%)' },
            activeTab === short_descriptions.multi_currency.value && { transform: 'translateX(-500%)' },
            activeTab === short_descriptions.broker.value && { transform: 'translateX(-600%)' }
          ]}
          transition={{ duration: handleCreateTabs(userInfo?.data).length > 1 ? 0.5 : 0 }}
          css={{}}
        >
          <DepositCardDocuments
            twStyle={[activeTab !== short_descriptions.deposit.value && tw`h-0`]}
            accounts={depositAccounts}
            product_code={tabs.find(item => item.value === short_descriptions.deposit.value).product_code}
            card_type={short_descriptions.deposit.value}
            fetchCreateDocument={fetchCreateDocument}
            handleDownloadDocument={handleDownloadDocument}
            setDownloadError={setDownloadError}
            setDownloadIsLoading={setDownloadIsLoading}
            setDownloadSuccess={setDownloadSuccess}
            setOpen={setOpen}
            userDocuments={[]}
            card_product={short_descriptions.deposit.type}
          />
          <DepositCardDocuments
            twStyle={[activeTab !== short_descriptions.invest.value && tw`h-0`]}
            accounts={investAccounts}
            product_code={tabs.find(item => item.value === short_descriptions.invest.value).product_code}
            card_product={short_descriptions.invest.type}
            card_type={short_descriptions.invest.value}
            fetchCreateDocument={fetchCreateDocument}
            handleDownloadDocument={handleDownloadDocument}
            setDownloadError={setDownloadError}
            setDownloadIsLoading={setDownloadIsLoading}
            setDownloadSuccess={setDownloadSuccess}
            setOpen={setOpen}
            userDocuments={[]}
          />

          {userInfo?.data.has_prepaid && <PrepaidCardDocuments mobile_phone={mobile_phone} />}

          <DepositCardDocuments
            twStyle={[activeTab !== short_descriptions.we_deposit.value && tw`h-0`]}
            accounts={weDepositAccounts}
            product_code={tabs.find(item => item.value === short_descriptions.we_deposit.value).product_code}
            card_type={short_descriptions.we_deposit.value}
            fetchCreateDocument={fetchCreateDocument}
            handleDownloadDocument={handleDownloadDocument}
            setDownloadError={setDownloadError}
            setDownloadIsLoading={setDownloadIsLoading}
            setDownloadSuccess={setDownloadSuccess}
            setOpen={setOpen}
            userDocuments={[]}
            card_product={short_descriptions.we_deposit.type}
          />
          <DepositCardDocuments
            twStyle={[activeTab !== short_descriptions.we_multi_currency.value && tw`h-0`]}
            accounts={weMultiCurrencyAccounts}
            product_code={tabs.find(item => item.value === short_descriptions.we_multi_currency.value).product_code}
            card_type={short_descriptions.we_multi_currency.value}
            fetchCreateDocument={fetchCreateDocument}
            handleDownloadDocument={handleDownloadDocument}
            setDownloadError={setDownloadError}
            setDownloadIsLoading={setDownloadIsLoading}
            setDownloadSuccess={setDownloadSuccess}
            setOpen={setOpen}
            userDocuments={[]}
            card_product={short_descriptions.we_multi_currency.type}
          />
          <DepositCardDocuments
            twStyle={[activeTab !== short_descriptions.multi_currency.value && tw`h-0`]}
            accounts={multiCurrencyAccounts}
            product_code={tabs.find(item => item.value === short_descriptions.multi_currency.value).product_code}
            card_type={short_descriptions.multi_currency.value}
            fetchCreateDocument={fetchCreateDocument}
            handleDownloadDocument={handleDownloadDocument}
            setDownloadError={setDownloadError}
            setDownloadIsLoading={setDownloadIsLoading}
            setDownloadSuccess={setDownloadSuccess}
            setOpen={setOpen}
            userDocuments={[]}
            card_product={short_descriptions.multi_currency.type}
          />

          <DepositCardDocuments
            twStyle={[activeTab !== short_descriptions.broker.value && tw`h-0`]}
            accounts={brokerAccounts}
            product_code={tabs.find(item => item.value === short_descriptions.broker.value).product_code}
            card_type={short_descriptions.broker.value}
            fetchCreateDocument={fetchCreateDocument}
            handleDownloadDocument={handleDownloadDocument}
            setDownloadError={setDownloadError}
            setDownloadIsLoading={setDownloadIsLoading}
            setDownloadSuccess={setDownloadSuccess}
            setOpen={setOpen}
            userDocuments={[]}
            card_product={short_descriptions.broker.type}
          />
        </motion.div>
        <Modal open={open} setOpen={setOpen} withCloseIcon={false} outsideClose={false}>
          <div tw='flex flex-col items-center gap-5 p-5'>
            {downloadIsLoading && (
              <>
                <SubTitle
                  text={
                    <span>
                      Формируем документ.
                      <br /> Пожалуйста, подождите
                    </span>
                  }
                  twStyle={tw`text-center`}
                />
                <ClipLoader size={30} speedMultiplier={0.6} color='#4f9d3a' />
              </>
            )}

            {downloadSuccess && (
              <>
                <div tw='w-[104px] h-[104px] flex items-center justify-center rounded-full mx-auto bg-green-alpha '>
                  <BigCheckIcon />
                </div>
                <SubTitle text={<span>Документ скачан</span>} />
                <Button variant={'text'} onClick={handleResetAllStates}>
                  Закрыть
                </Button>
              </>
            )}
            {downloadError && (
              <>
                <div tw='w-[104px] h-[104px] flex items-center justify-center rounded-full mx-auto bg-yellow-alpha20'>
                  <BigExclamationIcon />
                </div>
                <SubTitle
                  text={
                    <span>
                      <b> Произошла ошибка </b>
                      <br /> {createDocumentError?.data?.error?.description}
                    </span>
                  }
                  twStyle={tw`text-center`}
                />
                <Button variant={'text'} onClick={handleResetAllStates}>
                  Закрыть
                </Button>
              </>
            )}
          </div>
        </Modal>
      </motion.div>
    </AnimatePresence>
  );
}

export default MyDocumentsMainProfile;

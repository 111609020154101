import * as React from 'react';
const CardIconMyDocs = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <path
      fill='#70FF6E'
      d='M5.014 3.998a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-10a3 3 0 0 0-3-3zm0 2h14a1 1 0 0 1 1 1v1h-16v-1a1 1 0 0 1 1-1m-1 4h16v7a1 1 0 0 1-1 1h-14a1 1 0 0 1-1-1zm3 2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1z'
    />
  </svg>
);
export default CardIconMyDocs;

import * as React from 'react';

function WarningIcon1({ color }) {
  return (
    <svg width={30} height={24} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zM12 17v-5'
        stroke={color ? color : '#EEBB08'}
        fill='none'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.006 8h-.01'
        stroke={color ? color : '#EEBB08'}
        fill='none'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default WarningIcon1;

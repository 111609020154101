import 'twin.macro';

import React from 'react';
import { useEffect } from 'react';
import tw from 'twin.macro';
function Tabs({ activeTab, setActiveTab, tabs, defaultActiveTab }) {
  useEffect(() => {
    setActiveTab(defaultActiveTab?.value);
    return () => {
      setActiveTab(null);
    };
  }, []);

  return (
    <div tw='flex border-b border-grey gap-5 overflow-scroll '>
      {tabs.map(tab => (
        <button
          tw='pb-3'
          key={tab.value}
          onClick={() => setActiveTab(tab.value)}
          css={[
            tw`text-secondary sm:min-w-0 min-w-[200px] `,
            activeTab === tab.value && tw`text-tertiary border-b-2 border-[#70FF6E] `
          ]}
        >
          <span>{tab.title}</span>
        </button>
      ))}
    </div>
  );
}

export default Tabs;

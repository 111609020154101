import { createSelector, createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { PURGE } from 'redux-persist';

import { applicationApi } from '@/app/api/application';
import { applyApi } from '@/app/api/apply';
// import { iinApplicationApi } from '@/app/api/iin-application';
import { shortLinkApi } from '@/app/api/short-link';

const initialState = {
  selectedCountry: null,
  mobile_phone: null,
  iin: null,
  signDucumentsSuccess: false,
  registrationIsValid: false,
  cardType: null,
  application_uuid: null,
  step: null
};

const baseSelector = state => state.app;

export const getSelectedCountry = createSelector([baseSelector], state => state.selectedCountry);
export const getApplyData = createSelector([baseSelector], state => ({
  country: state.selectedCountry,
  mobile_phone: state.mobile_phone,
  iin: state.iin
}));
export const getMobilePhone = createSelector([baseSelector], state => state.mobile_phone);
export const getIsSignDucumentsSuccess = createSelector([baseSelector], state => state.signDucumentsSuccess);
export const getCardType = createSelector([baseSelector], state => state.cardType);
export const getApplicationUuid = createSelector([baseSelector], state => state.application_uuid);
export const getStep = createSelector([baseSelector], state => state.step);

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setApplyData: (state, { payload }) => {
      state.iin = payload.iin;
      state.mobile_phone = payload.mobile_phone;
      state.selectedCountry = payload.country;
    },
    setApplicationUuid: (state, { payload }) => {
      state.application_uuid = payload;
    },
    setMobileNumber: (state, action) => {
      state.mobile_phone = action.payload;
    },
    setSignDucumentsSuccess: state => {
      state.signDucumentsSuccess = true;
    },
    setRegistrationIsValid: (state, { payload }) => {
      state.registrationIsValid = payload;
    },
    setCardType: (state, { payload }) => {
      state.cardType = payload.replaceAll(' ', '');
    },
    setNewStep: (state, { payload }) => {
      state.step = payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(PURGE, () => initialState)
      .addMatcher(applicationApi.endpoints.verify.matchFulfilled, (state, { payload }) => {
        const userData = jwt_decode(payload.data.access);
        state.mobile_phone = userData.mobile_phone;
        state.selectedCountry = userData.citizenship; // example: {name: 'Россия', code2: 'RU', code3: 'RUS'}
      })
      .addMatcher(applicationApi.endpoints.applyAuth.matchFulfilled, (state, { payload }) => {
        const userData = jwt_decode(payload.data.access);
        state.mobile_phone = userData.mobile_phone;
        state.selectedCountry = userData.citizenship; // example: {name: 'Россия', code2: 'RU', code3: 'RUS'}
      })
      .addMatcher(shortLinkApi.endpoints.shortLinkDecode.matchFulfilled, (state, { payload }) => {
        const userData = jwt_decode(payload.data.access);
        state.mobile_phone = userData.mobile_phone;
        state.selectedCountry = userData.citizenship; // example: {name: 'Россия', code2: 'RU', code3: 'RUS'}
      })
      .addMatcher(applyApi.endpoints.signDocuments.matchFulfilled, state => {
        state.signDucumentsSuccess = true;
      });
  }
});

export const {
  setApplyData,
  setMobileNumber,
  setSignDucumentsSuccess,
  setRegistrationIsValid,
  setCardType,
  setApplicationUuid,
  setNewStep
} = appSlice.actions;

export default appSlice.reducer;

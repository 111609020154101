export const nr_steps = {
  common: {
    button_go: 'Продолжить',
    button_cancel: 'Отмена'
  },
  steps: {
    apply: 'Заявка',
    biometry: 'Биометрия',
    address: 'Получение адреса',
    internal_checks: 'Проверки банка',
    success: 'Готово!'
  },
  apply: {
    title: 'Заполните форму',
    country: { label: 'Страна гражданства', placeholder: 'Выберите страну' },
    mobile_phone: { label: 'Номер моб. телефона' },
    iin: {
      label: 'Индивидуальный идентификационный номер',
      placeholder: 'Введите ИИН'
    },
    agreement: {
      title: 'Условия соглашения',
      checkbox1: {
        text: 'Я соглашаюсь на сбор и обработку',
        link: 'персональных данных'
      },
      checkbox2: {
        text: 'Я подтверждаю отсутствие у меня признаков принадлежности к FATCA'
      },
      checkbox3: {
        text: 'Я не являюсь публичным должностным лицом'
      }
    },
    button: 'Далее'
  },
  verify: {
    title: 'Введите код',
    code_sended: 'Код отправлен на номер',
    otp_label: 'Введите код из SMS',
    button: 'Далее',
    resend: 'Переотправить код',
    resend_after: 'Переотправить код через:',
    change_number: 'Изменить номер'
  },
  biometry: {
    title: 'Подтвердите свою личность',
    button_go: 'Начать видеопроверку',
    button_cancel: 'Отмена',
    mobile_steps: {
      title: 'Перейти на мобильное устройство',
      step1: 'откройте камеру на вашем смартфоне',
      step2: 'наведите на QR код',
      step3: 'перейдите на предложенный сайт',
      step4: 'пройдите биометрию'
    },
    cancel_modal: {
      question: 'Вы действительно хотите отменить заявку?',
      no: 'Нет',
      yes: 'Да'
    },
    modal: {
      recommendation:
        'Рекомендуем пройти видеоидентификацию с использованием мобильного устройства. Вам понадобится камера для фотографирования загранпаспорта и прохождения селфи.',
      rules: {
        title: 'Убедитесь, что:',
        rule1: 'Вы находитесь в помещении с хорошим освещением, и сзади Вас нет источника света',
        rule2: 'В кадр не попадают посторонние предметы, помимо Вашего загранпаспорта',
        rule3: 'Ваше лицо полностью помещается в рамку на экране предпросмотра'
      },
      button: 'Продолжить'
    }
  },
  address: {
    title1: 'Укажите адрес регистрации',
    registration: {
      country: {
        label: 'Страна',
        placeholder: 'Выберите страну'
      },
      city: {
        label: 'Введите город',
        placeholder: 'Город/Населенный пункт'
      },
      street: {
        label: 'Введите улицу',
        placeholder: 'Улица'
      },
      house: {
        label: 'Номер дома',
        placeholder: 'Введите номер дома'
      },
      flat: {
        label: 'Введите квартиру',
        placeholder: 'Квартира'
      },
      entrance: {
        label: 'Введите подъезд',
        placeholder: 'Подъезд'
      }
    },
    title2: 'Выберите страну пребывания',
    actualAddress: {
      title: 'Мой адрес пребывания',
      country: {
        placeholder: 'Выберите страну',
        caption: 'В зависимости от выбранной страны будет доступен определенный тип карты'
      },
      errorMsg: 'Прием заявок для указанной страны будет доступен позже',
      city: {
        label: 'Город',
        placeholder: 'Выберите город',
        caption:
          'Цифровая карта доступна во всех городах, в случае если ваш город отсутствует в списке, поставьте галочку ниже'
      },
      street: {
        label: 'Введите улицу',
        placeholder: 'Улица'
      },
      entrance: {
        label: 'Корпус',
        placeholder: 'Корпус'
      },
      house: {
        label: 'Номер дома',
        placeholder: 'Номер дома'
      },
      flat: {
        label: 'Введите квартиру',
        placeholder: 'Квартира'
      },
      checkbox: 'Мой адрес пребывания соответствует адресу регистрации'
    },
    title3: 'Выберите тип карты',
    cardTypes: {
      virtual: {
        title: 'Цифровая карта',
        caption:
          'Карту можно добавить в Apple Pay, Samsung Pay или GPay. Управление картой доступно в мобильном приложении  Freedom Bank'
      },
      plastic: {
        title: 'Пластиковая карта',
        caption:
          'Доставка пластика займёт от 1 до 5 дней. Цифровой картой можно пользоваться сразу после подачи заявки '
      }
    },
    title4: 'Выберите город',
    delivery: {
      title: 'Укажите адрес доставки',
      address: {
        label: 'Введите адрес',
        placeholder: 'Микрорайон, Улица, дом'
      },
      flat: {
        label: 'Введите квартиру',
        placeholder: 'Квартира/Офис'
      },
      entrance: {
        label: 'Введите подъезд',
        placeholder: 'Подъезд'
      },
      rules: {
        rule1_1: 'Обязательное условие выдачи карты:',
        rule1_2: 'курьер должен вас сфотографировать',
        rule2_1: 'Срок доставки :',
        rule2_2: '2 рабочих дня'
      }
    },
    pickup: {
      title: 'Адреса самовывоза',
      rules: {
        rule1: 'Ожидайте смс уведомления',
        rule2_1: 'Срок изготовления : ',
        rule2_2: 'до 2х рабочих дней'
      }
    },
    boxToggler: {
      delivery: 'Доставка',
      pickup: 'Самовывоз'
    }
  },
  internal_checks: {
    title: 'Выполняется проверка'
  },
  sign_doc: {
    title: 'Подписание документов',
    button: 'Подписать с помощью OTP',
    open_pdf: 'Открыть .PDF'
  },
  videocall: {
    modal_text: 'Для завершения процесса выпуска карточки, Вам необходимо пройти видеоверификацию Банка',
    title: 'Видеозвонок',
    caption1: 'Процесс видеозвонка может занять до 5 минут.',
    caption2: 'Пожалуйста, не закрывайте окно браузера.',
    button: 'Начать видеозвонок'
  }
};

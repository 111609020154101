export const deposit = {
  deposit_banner: {
    title: 'Мультивалютная депозитная карта для иностранных граждан',
    subtitle: {
      benefit1: 'Оформление за 10 минут в отделении банка',
      benefit3: '7 полноценных счетов в тенге, долларах, евро, рублях, юанях, лирах и дирхамах',
      benefit4: 'Вознаграждение до 0,1% на остаток во всех валютах'
    },
    button: 'Подать заявку',
    caption: 'Приготовьте ИИН, загранпаспорт и смартфон с камерой'
  },
  deposit_benefits: {
    title: 'Удобная карта на каждый день',
    card_1: 'Карта привязана к 7 депозитным счетам',
    card_2: 'У каждого счёта — свой IBAN для межбанковских переводов',
    card_3: 'Много разных способов пополнения карты и вывода денег',
    card_4: 'Пополнение карты и конвертации — без комиссий',
    card_5: 'Оплаты по всему миру и в интернете — без комиссий',
    card_6: 'Переводы на депозиты и счета внутри банка — без комиссий',
    card_9: {
      title: 'Вознаграждение на остаток',
      subtitle:
        'выплачивается раз в месяц в случае, если суммарный ежедневный остаток по вкладам не опускается ниже 500 000 тенге (или эквивалента в валюте)',
      card1_title: '0,1%',
      card1_caption: 'во всех валютах'
    }
  },
  deposit_replenish: {
    title: 'Удобно пополнять карту',
    container_1: {
      title: 'Банковским переводом (Свифт)',
      caption: 'На свой счёт в долларах, евро или рублях',
      p1: 'Перевод можно отправить в долларах, евро или рублях',
      p2: 'Реквизиты ваших счетов доступны в мобильном приложении  Freedom Bank',
      p3: 'Лимиты и комиссии — согласно тарифов банка-отправителя.',
      p4: 'Банк оставляет за собой право запросить документы, подтверждающие источник происхождения денег.'
    },
    container_2: {
      title: 'Переводом с карты Visa или Mastercard',
      caption: 'Подойдёт любая карта Visa или Mastercard, кроме выпущенных в РФ',
      p1: 'Пополнить свою карту можно с любой карты Visa или Mastercard (кроме выпущенных в РФ) через мобильное приложение Freedom Bank в разделе «Переводы» — «С карты на карту».',
      p2: 'Лимиты на переводы с карт Visa: на один перевод — 25 000 $, сумма переводов в сутки — 50 000 $, в неделю — 100 000 $, в месяц — 200 000 $ (эквивалент в валюте перевода).',
      p3: 'Лимиты на переводы с карт Mastercard: лимит – на один перевод — 5 000 $, сумма всех переводов за сутки — 20 000 $, за неделю — 50 000 $, за месяц — 100 000 $ (эквивалент в валюте перевода).',
      p4: 'У нас нет комиссий на пополнение с карт. Но комиссии и ограничения могут быть у банка, выпустившего карту отправителя.'
    },
    // container_3: {
    //   title: 'Переводом с карты МИР',
    //   caption: 'С помощью сервиса Moneysend',
    //   p2: 'Лимиты и комиссии — согласно условий сервиса.',
    //   p3: 'Банк, выпустивший карту отправителя, может дополнительно удержать свою комиссию.'
    // },
    // container_4: {
    //   title: 'Со счёта Цифра Банка (РФ)',
    //   caption: 'В долларах, евро, рублях и тенге из мобильного приложения Цифра Банка',
    //   p2: 'Валюты перевода — доллары, евро, рубли и тенге.',
    //   p3: 'Лимиты и комиссии — согласно условий Цифра Банка.'
    // },
    container_5: {
      title: 'Наличными в банкомате или отделении банка',
      caption: 'на территории Казахстана',
      p1: 'Пополняйте счета в банкоматах и отделениях Freedom Bank и кассах Freedom Mobile на территории Казахстана.',
      p2: 'Банкоматы банка и кассы Freedom Mobile принимают только тенге.',
      p3: 'В отделении Банка принимают тенге и иностранную валюту.',
      p4: '',
      p5: 'Банк оставляет за собой право запросить документы, подтверждающие источник происхождения денег.'
    },
    container_6: {
      title: 'Переводом со счёта в Тинькофф Банке (РФ)',
      caption: 'из мобильного приложения по номеру телефона',
      p1: 'Пополнить карту можно переводом в рублях из приложения Тинькофф Банка. Отправляйте деньги себе или третьему лицу на казахстанский или российский номер.',
      p2: 'Лимиты и комиссии — согласно тарифам Тинькофф Банка.',
      p3: 'Подробная инструкция.'
    }
  },
  deposit_withdaw: {
    title: 'Удобно выводить деньги',
    container_1: {
      title: 'Банковским переводом (Свифт)',
      caption: 'В рублях или долларах из мобильного приложения Freedom Bank',
      p1: 'Перевод можно отправить в рублях или долларах из мобильного приложения Freedom Bank.',
      p2: 'Вам понадобятся банковские реквизиты получателя.',
      p3: 'Перевод занимает от 3 рабочих дней.',
      p4: 'Комиссии от 0,15% от суммы перевода (минимум 8 000 тенге или эквивалент в валюте).',
      p5: 'Банк оставляет за собой право запросить документы, подтверждающие источник происхождения денег.'
    },
    container_2: {
      title: 'Переводом на карту Visa или Mastercard',
      caption: 'кроме выпущенных в РФ',
      p1: 'Перевести деньги можно на любую карту Visa или Mastercard (кроме выпущенных в РФ) через мобильное приложение Freedom Bank в разделе «Переводы» — «С карты на карту».',
      p2: 'Деньги поступят моментально.',
      p3: 'Лимит на один перевод — 5 000 $, суммарно в месяц — 100 000 $ (или эквивалент в валюте).',
      p4: 'У нас нет комиссий на пополнение с карт. Но комиссии и ограничения могут быть у банка, выпустившего карту отправителя.'
    },
    container_3: {
      title: 'Банковским переводом по Казахстану',
      caption: 'В тенге по номеру счёта',
      p1: 'Перевести деньги в любой банк Казахстана можно в мобильном приложении Freedom Bank.',
      p2: 'Лимит — 10 млн тенге на одну операцию. Количество операций не ограничено.',
      p3: 'Комиссия — 200 тенге.'
    },
    container_4: {
      title: 'Наличными в отделении банка или банкоматах',
      caption: 'на территории Казахстана и других стран, за исключением РФа',
      subtitle1: 'В банкоматах или кассах Freedom Bank',
      p1: 'Лимит в банкоматах — 500 000 тенге в месяц (или эквивалент в валюте).',
      p2: 'Лимитов на снятие с карты с помощью pos-терминала нет.',
      p3: 'Комиссия — 1,5% от суммы (минимум 1 500 тенге или эквивалент в валюте).',
      subtitle2: 'В кассе Freedom Bank без предъявления карты',
      p4: 'Лимитов нет.',
      p5: 'Комиссия — 2% от суммы.',
      subtitle3: 'В банкоматах и кассах других казахстанских банков',
      p6: 'Лимит в банкоматах — 500 000 тенге в месяц (или эквивалент в валюте).',
      p7: 'Лимитов на снятие с карты с помощью pos-терминала нет (но они могут быть у банка, в котором вы снимаете наличные).',
      p8: 'Комиссия — 1,5% от суммы (минимум 1 500 тенге или эквивалент в валюте).',
      subtitle4: 'В банкоматах и кассах банков вне Казахстана и России',
      p9: 'Лимит в банкоматах — 500 000 тенге в месяц (или эквивалент в валюте).',
      p10: 'Лимитов на снятие с карты с помощью pos-терминала нет (но они могут быть у банка, в котором вы снимаете наличные).',
      p11: 'Комиссия — 2% от суммы + 1 500 тенге (или эквивалент в валюте).',
      p12: 'Лимит на снятие наличных в банкоматах можно изменить в мобильном приложении  Freedom Bank или обратившись в колл-центр банка.'
    }
  },
  deposit_condition: {
    title: 'Условия и тарифы',
    line1: {
      column: 'Вид карты',
      value: 'Неименная Mastercard World',
      caption: 'пластиковая, цифрового аналога нет'
    },
    line2: { column: 'Валюта', value: 'тенге, доллары, евро, рубли, юань, лира, дирхам' },
    line3: {
      column: 'Выпуск',
      value: ' 30 000 ₸',
      caption: 'спишутся автоматически при первом поступлении средств'
    },
    line4: { column: 'Обслуживание', value: 'бесплатно ' },
    line5: { column: 'Срок действия', value: '3 года' },
    line6: { column: 'Переводы на карты других банков РК', value: '1,5%' },
    line7: { column: 'Переводы на карты зарубежных банков', value: '1%', caption: 'минимум 250 тенге' },
    line8: { column: 'Пополнения', value: 'бесплатно' },
    line9: { column: 'Конвертации', value: 'бесплатно' },
    line10: { column: 'Снятие наличных', value: 'от 1,5%', caption: 'минимум 250 тенге' },
    line11: {
      column: 'Максимальная сумма единоразового перевода c карты на карту',
      value: '5 000 $ за один раз'
    },
    line12: {
      column: 'Максимальная сумма всех переводов с карты на карту',
      value: '100 000 $ в месяц'
    },
    tariff: 'Полный список тарифов по карте'
  },
  deposit_qa: {
    title: 'Вопросы и ответы по карте',
    tab1: 'Открытие и получение',
    tab2: 'Использование',
    tab3: 'Налоговые и юридические вопросы',
    tab4: 'Решение проблем'
  },
  deposit_info_banner: {
    title: 'Управляйте счетами и картой из приложения',
    subtitle1: 'Переводы на карты, свифт-переводы, конвертации, оплата услуг, реквизиты счетов и многое другое.',
    subtitle2:
      'Ссылку на скачивание приложения, ваши логин и пароль пришлём в СМС сразу после после выпуска цифровой карты.'
  }
};

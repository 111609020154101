import '@/index.css';
import 'react-phone-input-2/lib/style.css';
import './locale/i18n';

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from '@/App';
import { persistor, store } from '@/app/store';
import { DeviceInfoProvider } from '@/contexts/device-info-context';
import { ThemeProvider } from '@/contexts/theme-context';
import GlobalStyles from '@/GlobalStyles';
import Page503 from '@/pages/503';
Sentry.init({
  dsn: 'https://1f0e4b2b9f494efb9681f44c51fdb6c9@sentry-prod.globerce.com/4',
  environment: 'production',
  tracesSampleRate: 0
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <ThemeProvider initialTheme='light'>
    <GlobalStyles />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DeviceInfoProvider>
          <BrowserRouter>
            <ErrorBoundary
              FallbackComponent={Page503}
              onReset={() => {
                // console.log('Reset Error');
              }}
            >
              <App />
            </ErrorBoundary>
          </BrowserRouter>
        </DeviceInfoProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

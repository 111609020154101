import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { getIsAuth } from '@/app/store/slices/auth';
import { nr_pathnames } from '@/app/utils/constants';

export const RequireAuth = ({ children }) => {
  const isAuth = useSelector(getIsAuth);
  const location = useLocation();

  const navigate = useNavigate();
  if (!isAuth) {
    return <Navigate to={nr_pathnames.landing} replace={true} />;
  }

  return children;
};

import 'react-phone-input-2/lib/high-res.css';

import React from 'react';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import tw from 'twin.macro';

import { Caption } from '../caption/caption';
import { SubBody } from '../sub-body/sub-body';

const CountryPhoneInput = PhoneInput.default ? PhoneInput.default : PhoneInput;

const exceptionCountries = ['de', 'rs', 'il', 'me', 'uz', 'hr'];
const InternationalPhoneInput = ({
  country = 'KZ',
  control,
  errors,
  onKeyPress,
  setCountryFormatLength,
  setValue = Function.prototype(),
  isExeceptionCountry,
  setIsExeceptionCountry = Function.prototype(),
  onlyCountries,
  key,
  label,
  trigger
}) => {
  const [countryCode, setCountryCode] = useState('kz');
  const [error, setError] = useState('');
  const { i18n, t } = useTranslation();
  const lang = i18n.language.split('-')[0];
  const {
    field: { value, onChange, name },
    fieldState: { invalid }
  } = useController({ name: 'mobile_phone', control: control, shouldUnregister: false });

  const onChangeHandler = (value, country) => {
    onChange(value);

    // setValue('mobile_phone', '');
    setCountryCode(country.countryCode);
    setIsExeceptionCountry(exceptionCountries.includes(country?.countryCode));

    let countryFormatLength = '';

    switch (country?.countryCode) {
      case 'de':
        country['format'] = '+.. ... .......';
        break;
      case 'rs':
        country['format'] = '+.. ... .......';
        break;
      case 'il':
        country['format'] = '+.. ... .......';
        break;
      case 'uz':
        country['format'] = '+... .. .......';
        break;
      case 'hr':
        country['format'] = '+... ... ... ..';
        break;
      default:
        break;
    }
    countryFormatLength =
      country?.countryCode === 'bg' || country?.countryCode === 'me' || country?.countryCode === 'hr'
        ? 11
        : String(country.format)
            .split(/[-()\s+]+/)
            .join('').length;

    setCountryFormatLength(countryFormatLength);
    if (isExeceptionCountry) {
      if (value.length === countryFormatLength + 1 || value.length === countryFormatLength) {
        !value.includes('+') && setValue('mobile_phone', String(value).replaceAll(' ', ''));
      }
      if (value.length !== countryFormatLength && value.length !== countryFormatLength + 1) {
        setError('Мобильный номер должен состоять из ' + ' ' + countryFormatLength + ' ' + 'цифр');
      } else {
        setError('');
        trigger('mobile_phone');
      }
    } else {
      if (value.length === countryFormatLength) {
        !value.includes('+') && setValue('mobile_phone', String(value).replaceAll(' ', ''));
      }
      if (value.length !== countryFormatLength) {
        setError('Мобильный номер должен состоять из ' + ' ' + countryFormatLength + ' ' + 'цифр');
      } else {
        setError('');
      }
    }
  };

  return (
    <div>
      <label htmlFor={'phone_input'} tw='ml-s13 text-sm'>
        <SubBody text={<span>Номер моб. телефона</span>} />
      </label>
      <CountryPhoneInput
        control={control}
        id={name}
        value={value}
        name={name}
        country={onlyCountries ? onlyCountries[0] : 'kz'}
        localization={lang === 'ru' ? ru : ''}
        InputProps={{
          onKeyPress: onKeyPress,
          name: name,
          required: true,
          autoFocus: false
        }}
        onlyCountries={onlyCountries ? [...onlyCountries] : []}
        enableLongNumbers
        containerStyle={tw`flex font-sans`}
        onCountryChange={value => {
          // console.log('scdsdcdds', value);
        }}
        inputStyle={tw`rounded-s14 bg-input outline-none text-primary font-bold placeholder-input
         py-[23px] border border-transparent ml-16 w-[100%] sm:w-auto
        focus:border-input-active disabled:cursor-not-allowed text-s14 pl-5`}
        buttonStyle={tw`h-[48px] w-[58px] border-none bg-input rounded-xl hover:bg-button-hover pl-1`}
        placeholder=''
        dropdownStyle={tw`rounded-md max-h-[230px] bg-primary`}
        buttonClass={'selectButton'}
        onChange={onChangeHandler}
        key={key ? key : null}
        isValid={!invalid}
        areaCodes={{ kz: ['7'] }}
      />

      {(error || errors) && (
        <span tw=''>
          <Caption twStyle={tw`text-error ml-3 mt-1`} text={error || errors?.mobile_phone?.message} />
        </span>
      )}
    </div>
  );
};

export default InternationalPhoneInput;

import 'react-datepicker/dist/react-datepicker.css';

import React, { useMemo, useState } from 'react';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import tw from 'twin.macro';

import { getAccessToken } from '@/app/store/slices/auth';
import CardIconMyDocs from '@/assets/images/icons/CardIconMyDocs';
import FolderIcon from '@/assets/images/icons/FolderIcon';
import PdfIcon from '@/assets/images/icons/PdfIcon';
import { BigTitle, BodyText, Select } from '@/components';
import DocumentContainer from '@/components/document-container/document-container';

import DeliverySuccess from '../delivery-success';
import DepositDelivery from './deposit-delivery';

const handleGetUrl = (data, value) => {
  if (value === 'APPLICANT_AGREEMENT_STATEMENT_MERGED') {
    return `${import.meta.env.NR_HOMEPAGE}${data?.find(doc => doc?.type === value)?.url}`;
  } else {
    return `${data?.find(doc => doc?.type === value)?.url}`;
  }
};

const bankDocumentsNamesDeposit = {
  SANCTION_LETTER: 'Письмо об отсутствии подсанкционной деятельности',
  APPLICANT_AGREEMENT_STATEMENT_MERGED: 'Заявление физического лица и согласие клиента',
  TECHNOLOGICAL_MAP: 'Заявление и распоряжение на вклад',
  APPLICANT_AGREEMENT: 'Согласие клиента'
};

const bankDocumentsNamesInvest = {
  SANCTION_LETTER: 'Письмо об отсутствии подсанкционной деятельности',
  INVEST_CARD: 'Постоянное распоряжение отправителя денег',
  PROVISION_OF_BROKERAGE: 'Заявление о присоединении оказания брокерских услуг',
  APPLICANT_AGREEMENT_STATEMENT_MERGED: 'Заявление физического лица и согласие клиента'
};

const handleConvertDocuments = (documents, card_product) => {
  const newArray = documents
    ? documents?.map(item => {
        return {
          value: handleGetUrl(documents, item.type),
          label:
            card_product === 'DEPOSIT' ? bankDocumentsNamesDeposit[item?.type] : bankDocumentsNamesInvest[item?.type]
        };
      })
    : [];

  newArray?.push({
    value: 'https://bankffin.kz/storage/docs/XlDAVVGo5j8xjsL5uRfXt4SOB87bk04Le0H03fCu.pdf',
    label: 'Публичный договор с банком'
  });

  return newArray;
};

export const filterAccountsByAccountType = (data, activePan) => {
  const newArray = [{ depo: [] }, { client: [] }]; // тут будет массив объектов

  const sortedByPan = data.filter(item => item.masked_card_number === activePan);

  console.log('sortedByPan', sortedByPan);
  sortedByPan?.map(item =>
    item.account_type === 'DEPO'
      ? newArray[0].depo?.push({
          title: item.currency.toUpperCase(),
          value: item.currency.toLowerCase()
        })
      : newArray[1].client?.push({
          title: item.currency.toUpperCase(),
          value: item.currency.toLowerCase()
        })
  );

  return newArray;
};

const sortPans = array => {
  if (!array) return [];

  const uniqueCards = new Set();
  const sortedArray = [];

  array.forEach(current => {
    if (!uniqueCards.has(current.value)) {
      uniqueCards.add(current.value);
      sortedArray.push(current);
    }
  });

  return sortedArray;
};

function DepositCardDocuments({
  accounts,
  card_type,
  handleDownloadDocument,
  setDownloadIsLoading,
  setOpen,
  fetchCreateDocument,
  userDocuments,
  twStyle,
  card_product,
  product_code
}) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [activeSelectReq, setActiveSelectReq] = useState('rus');
  const [activeSelectHelp, setActiveSelectHelp] = useState('rus');
  const [activeSelectOr, setActiveSelectOr] = useState(null);
  const [activeSelectFns, setActiveSelectFns] = useState(null);
  const [deliveryInfo, setDeliveryInfo] = useState([]);
  const [createCardSuccess, setCreateCardSuccess] = useState(false);

  // const pans = [
  //   {
  //     label: '5269********0871',
  //     value: '5269********0871'
  //   },
  //   {
  //     label: '5269********0872',
  //     value: '5269********0822'
  //   }
  // ];

  const pans = sortPans(
    accounts.map(item => {
      return {
        label: item.masked_card_number,
        value: item.masked_card_number
      };
    })
  );

  const { control, watch, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      [`ORDERING_${card_type}`]: `CLIENT_${card_type}`,
      [`REQUISITES_FNS_${card_type}`]: `CLIENTS_${card_type}`,
      pans: pans[0]?.value
    }
  });

  const activePan = watch('pans');

  const filteredAccounts = useMemo(() => filterAccountsByAccountType(accounts, activePan), [accounts, activePan]);

  const documents = useMemo(
    () => [
      {
        title: 'Реквизиты счетов (IBAN) для входящих переводов ',
        buttonText: 'Скачать',
        withToggler: true,
        documentType: 'REQUISITES',
        selects: [
          { value: 'rus', title: 'На русском' },
          { value: 'eng', title: 'На английском' }
        ],
        icon: <PdfIcon />
      },
      {
        title: 'Шаблон уведомления ФНС РФ о счетах в зарубежных банках',
        buttonText: 'Скачать',
        withToggler: true,
        stickerText: 'в налоговую',
        documentType: 'REQUISITES_FNS',
        radios:
          filteredAccounts[0].depo.length > 0 && filteredAccounts[1].client.length > 0
            ? [
                { value: `DEPOS_${card_type}`, title: 'Сберегательный' },
                { value: `CLIENTS_${card_type}`, title: 'Текущий' }
              ]
            : [],
        selects: [
          { value: 'rub', title: 'RUB' },
          { value: 'kzt', title: 'KZT' },
          { value: 'usd', title: 'USD' },
          { value: 'eur', title: 'EUR' }
        ],
        withRadio: true,
        caption: 'Граждане РФ обязаны уведомить налоговую в течение 30 дней с даты открытия или закрытия счета',
        icon: <PdfIcon />
      },
      {
        title: 'Мои документы на открытие счета',
        buttonText: 'Открыть',
        withDropDown: true,
        documentType: 'BANK_DOCS',
        icon: <FolderIcon />
      },
      {
        title: 'Справка об открытых счетах с остатками и реквизитами банка',
        buttonText: 'Скачать',
        caption: 'подходит для оформления визы',
        withToggler: true,
        documentType: 'HELP_ON_ACCOUNTS_AND_BALANCES',
        selects: [
          { value: 'rus', title: 'На русском' },
          { value: 'eng', title: 'На английском' }
        ],
        icon: <PdfIcon />
      },
      {
        title: 'Справка об открытых счетах с реквизитами банка',
        buttonText: 'Скачать',
        documentType: 'HELP_ON_ACCOUNTS',
        icon: <PdfIcon />
      },
      {
        title: 'Выписка по счетам',
        buttonText: 'Скачать',
        withDate: true,
        withToggler: true,
        icon: <PdfIcon />,
        radios:
          filteredAccounts[0].depo.length > 0 && filteredAccounts[1].client.length > 0
            ? [
                filteredAccounts[0].depo.length > 0 && { value: `DEPO_${card_type}`, title: 'Сберегательный' },
                filteredAccounts[1].client.length > 0 && { value: `CLIENT_${card_type}`, title: 'Текущий' }
              ]
            : [],
        selects: [
          { value: 'rub', title: 'RUB' },
          { value: 'kzt', title: 'KZT' },
          { value: 'usd', title: 'USD' },
          { value: 'eur', title: 'EUR' }
        ],
        documentType: 'ORDERING'
      }
    ],
    [accounts]
  );
  const token = useSelector(getAccessToken);

  const bankDocuments = handleConvertDocuments(userDocuments, card_product);

  const handleCreateDocument = document_type => {
    switch (document_type) {
      case 'REQUISITES':
        if (activeSelectReq === 'rus') {
          handleFetchCreateDocument('REQUISITES_RUS');
        } else {
          handleFetchCreateDocument('REQUISITES_ENG');
        }
        break;
      case 'REQUISITES_FNS':
        handleFetchCreateDocument('REQUISITES_FNS');
        break;
      case 'HELP_ON_ACCOUNTS_AND_BALANCES':
        if (activeSelectHelp === 'rus') {
          handleFetchCreateDocument('HELP_ON_ACCOUNTS_AND_BALANCES_RUS');
        } else {
          handleFetchCreateDocument('HELP_ON_ACCOUNTS_AND_BALANCES_ENG');
        }
        break;
      case 'HELP_ON_ACCOUNTS':
        handleFetchCreateDocument('HELP_ON_ACCOUNTS_RUS');
        break;
      case 'ORDERING':
        handleFetchCreateDocument('ORDERING');
        break;
      default:
        break;
    }
  };

  const handleFetchCreateDocument = document_type => {
    let iban = null;
    let compareWord = String(watch(`${document_type}_${card_type}`)).includes('DEPO') ? 'DEPO' : 'CLIENT';
    const sortedByPan = accounts.filter(item => item.masked_card_number === activePan);
    if (String(document_type).includes('REQUISITES_FNS')) {
      iban = sortedByPan?.find(
        item =>
          String(item?.currency) === String(activeSelectFns).toUpperCase() &&
          String(item?.account_type) === String(compareWord) &&
          item
      );
    } else {
      iban = sortedByPan?.find(
        item =>
          String(item?.currency) === String(activeSelectOr).toUpperCase() &&
          String(item?.account_type) === String(compareWord) &&
          item
      );
    }

    let counter = 0;
    setOpen(true);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };

    const fetchDoc = () => {
      fetchCreateDocument(
        document_type === 'ORDERING'
          ? {
              document_type: document_type,
              start_date: new Intl.DateTimeFormat('en-CA', options).format(startDate),
              end_date: new Intl.DateTimeFormat('en-CA', options).format(endDate),
              iban: iban?.iban,
              card_product: card_product,
              product_code: product_code
            }
          : String(document_type).includes('REQUISITES_FNS')
          ? { document_type: document_type, iban: iban?.iban, card_product: card_product, product_code: product_code }
          : { document_type: document_type, card_product: card_product, product_code: product_code }
      )
        .unwrap()
        .then(() => {})
        .catch(e => {
          counter++;
          if (counter < 6 && e.status === 'FETCH_ERROR') {
            fetchDoc();
          }
          console.log(e);
        });
    };
    fetchDoc();
  };

  // useEffect(() => {
  //   setValue(`ORDERING_${card_type}`, `CLIENT_${card_type}`);
  //   setValue(`REQUISITES_FNS_${card_type}`, `CLIENTS_${card_type}`);
  // }, []);

  const handleCheckGetActiveItem = useCallback(doctype => {
    switch (doctype) {
      case 'REQUISITES':
        return setActiveSelectReq;
      case 'REQUISITES_FNS':
        return setActiveSelectFns;
      case 'HELP_ON_ACCOUNTS_AND_BALANCES':
        return setActiveSelectHelp;
      case 'ORDERING':
        return setActiveSelectOr;
      default:
        break;
    }
  }, []);

  const handleClickButton = item => {
    if (item.withDropDown && String(watch('documents')).split('/')[7] === 'APPLICANT_AGREEMENT_STATEMENT_MERGED') {
      setOpen(true);
      setDownloadIsLoading(true);
      return handleDownloadDocument(watch('documents'), token, card_product);
    } else {
      return item.withDropDown ? window.open(watch('documents'), '_blank') : handleCreateDocument(item.documentType);
    }
  };

  const checkIsValid = documentType => {
    switch (documentType) {
      case 'ORDERING':
        return !activeSelectOr;
      case 'REQUISITES_FNS':
        return !activeSelectFns;
      case 'BANK_DOCS':
        return !watch('documents');
      default:
        return false;
    }
  };

  return (
    <div tw='flex flex-col gap-10 relative min-w-[100%]' css={[twStyle]}>
      {pans?.length === 1 ? (
        <div tw='px-8 py-4 rounded-2xl bg-[#444444] max-w-max flex gap-3 items-center'>
          <CardIconMyDocs /> <span tw='w-[0.5px] h-[20px] bg-current'></span> <span>{pans[0]?.label}</span>
        </div>
      ) : (
        <div tw='rounded-2xl max-w-max flex gap-3 items-center'>
          <Select control={control} name={'pans'} options={pans} tw='max-w-max' icon={<CardIconMyDocs />} />
        </div>
      )}
      {filteredAccounts?.length === 2 &&
        (filteredAccounts[0]?.depo?.length > 0 || filteredAccounts[1]?.client?.length > 0) && (
          <>
            <div tw='grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 '>
              {documents?.map(item => (
                <DocumentContainer
                  key={item.documentType}
                  document={item}
                  card_type={card_type}
                  bankDocuments={bankDocuments}
                  checkIsValid={checkIsValid}
                  control={control}
                  endDate={endDate}
                  accounts={filteredAccounts}
                  handleCheckGetActiveItem={handleCheckGetActiveItem}
                  handleClickButton={handleClickButton}
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  startDate={startDate}
                  watch={watch}
                />
              ))}
            </div>
            {card_type === 'VIRTUAL' && !createCardSuccess && (
              <div tw='bg-[#444444] p-8 rounded-2xl flex flex-col gap-5'>
                <BigTitle text={'Заказ пластиковой карты'} twStyle={tw`sm:text-[34px] text-[32px] text-[#70FF6E]`} />
                <BodyText
                  text={'Выпускается в дополнение к цифровой. Получить карту возможно в городах, указанных ниже:'}
                />
                <DepositDelivery
                  setCreateCardSuccess={setCreateCardSuccess}
                  setDeliveryInfo={setDeliveryInfo}
                  setOpen={setOpen}
                />
              </div>
            )}
            {createCardSuccess && <DeliverySuccess deliveryInfo={deliveryInfo} />}
          </>
        )}
    </div>
  );
}

export default DepositCardDocuments;

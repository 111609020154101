export const my_documents_auth = {
  title: 'Справки, документы и заказ пластиковой карты',
  caption: 'для клиентов, которые получили карту для иностранных граждан',
  auth: {
    title: 'Вход для клиентов'
  },
  functionality: {
    title: 'Что внутри',
    first: 'Заказ пластиковой карты с доставкой (в дополнение к цифровой)',
    second: 'Справка по реквизитам счетов (IBAN) и остаткам по карте',
    third: {
      title: 'Справка для уведомления ФНС РФ об открытии счёта в банке за рубежом',
      text: 'в налоговую'
    },
    fourth: 'Ваше заявление на выпуск карты и публичный договор в pdf',
    fifth: 'Выписка по счету'
  },
  login: {
    send_sms: 'Отправить SMS-код',
    get_sms: 'Получить SMS-код'
  }
};

export const my_documents = {
  title: 'Мои документы',
  exit: 'Выход',
  cards: {
    deposit: 'Депозитная карта',
    invest: 'Карта инвестора',
    prepaid: 'Предоплаченные карты'
  }
};

export const my_documents_deposit = {
  button_1: 'Скачать',
  button_2: 'Открыть',
  selector_1: 'На русском',
  selector_2: 'На английском',
  radio_1: 'Текущий',
  radio_2: 'Сберегательный',
  account: 'Счет',
  period: 'Период',
  documents: {
    document_1: {
      title: 'Реквизиты счетов (IBAN) для входящих переводов'
    },
    document_2: {
      title: 'Шаблон уведомления ФНС РФ о счетах в зарубежных банках',
      sticker_text: 'в налоговую',
      caption: 'Граждане РФ обязаны уведомить налоговую в течение 30 дней с даты открытия или закрытия счета'
    },
    document_3: {
      title: 'Документы между вами и банком'
    },
    document_4: {
      title: 'Справка об открытых счетах с остатками и реквизитами банка',
      caption: 'подходит для оформления визы'
    },
    document_5: {
      title: 'Справка об открытых счетах с реквизитами банка'
    },
    document_6: {
      title: 'Выписка по счетам'
    }
  }
};

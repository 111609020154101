import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
const deliverySchema = yup
  .object({
    address: yup.string().required('Это поле обязательное'),
    // street: yup.string().required('Это поле обязательное'),
    flat: yup.string().max(9, 'Максимальная длина 9 символов'),
    building: yup.string().required('Это поле обязательное')
  })
  .required('Это поле обязательное');

export const useDeliveryForm = () => {
  const {
    control,
    clearErrors,
    getValues,
    setValue,
    reset,
    setError,
    formState: { isValid },
    watch,
    resetField
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(deliverySchema),
    defaultValues: {
      address: '',
      city: '',
      street: '',
      building: '',
      flat: '',
      entrance: '',
      lat: null,
      lon: null
    }
  });

  return {
    control,
    clearErrors,
    getValues,
    setValue,
    reset,
    isValid,
    watch,
    resetField,
    setError
  };
};

import * as React from 'react';

function CalendarIcon(props) {
  return (
    <svg width={16} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M5.334 1.333v2M10.666 1.333v2M2.334 6.06h11.333M14 5.667v5.667c0 2-1 3.333-3.333 3.333H5.333C3 14.667 2 13.334 2 11.334V5.667c0-2 1-3.333 3.333-3.333h5.334C13 2.333 14 3.667 14 5.667z'
        stroke='#B3B3B3'
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M10.463 9.133h.006M10.463 11.133h.006M7.998 9.133h.006M7.998 11.133h.006M5.529 9.133h.006M5.529 11.133h.006'
        stroke='#B3B3B3'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </svg>
  );
}

export default CalendarIcon;

import * as React from 'react';

function FolderIcon(props) {
  return (
    <svg width={32} height={32} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M29.333 14.667v8c0 5.333-1.334 6.667-6.667 6.667H9.333c-5.334 0-6.667-1.334-6.667-6.667V9.334c0-5.334 1.333-6.667 6.667-6.667h2c2 0 2.44.587 3.2 1.6l2 2.667c.506.666.8 1.066 2.133 1.066h4c5.333 0 6.667 1.334 6.667 6.667z'
        stroke='#70FF6E'
        strokeWidth={1.5}
        strokeMiterlimit={10}
        fill='none'
      />
      <path
        d='M10.666 2.667h12c2.667 0 4 1.333 4 4v1.84'
        stroke='#70FF6E'
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </svg>
  );
}

export default FolderIcon;

import 'twin.macro';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import ReCAPTCHA from 'react-google-recaptcha';
import { useTimer } from 'react-timer-hook';

import { Button, OtpInput, SubBody } from '@/components';
import InternationalPhoneInput from '@/components/ui/input/international-phone-input';

const LoginContent = ({
  control,
  phone: mobilePhone,
  verifySubmit,
  authSuccess,
  handleCheckRobot,
  recaptchaValue,
  loading,
  reset,
  authReset,
  sendOtpError,
  getValues,
  setValue,
  authOpen,
  trigger,
  errors,
  mobilePhoneValid,
  twStyle,
  setCountryFormatLength,
  isExeceptionCountry,
  setIsExeceptionCountry
}) => {
  const [applyAuth, setApplyAuth] = useState(false);
  const [timerEnd, setTimerEnd] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const phone = mobilePhone?.replaceAll(' ', '');
  const { t } = useTranslation();
  useEffect(() => {
    if (sendOtpError?.data?.error?.code?.toLowerCase() === 'invalid_otp') {
      setApplyAuth(true);
      setInvalidOTP(true);
    }
  }, [sendOtpError]);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 120);

  const { seconds, minutes, start, restart } = useTimer({ expiryTimestamp: time, onExpire: () => setTimerEnd(true) });

  useEffect(() => {
    setApplyAuth(authSuccess);
    start();
  }, [authSuccess]);

  const resetHandler = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 120);
    setTimerEnd(false);
    reset('code');
    authReset();
    restart(time);
  };

  useEffect(() => {
    phone && setValue('mobile_phone', phone);
  }, []);

  useEffect(() => {
    if (mobilePhone === null) {
      setApplyAuth(false);
    }
  }, [mobilePhone]);

  const handleKeyPress = e => {
    e.charCode === 13 && mobilePhoneValid && verifySubmit();
  };

  return (
    <div tw='flex flex-col'>
      <div tw='bg-secondary p-5 space-y-4 px-12' css={[twStyle]}>
        <InternationalPhoneInput
          control={control}
          onKeyPress={handleKeyPress}
          errors={errors}
          setCountryFormatLength={setCountryFormatLength}
          setValue={() => {}}
          trigger={trigger}
          phone={phone}
          isExeceptionCountry={isExeceptionCountry}
          setIsExeceptionCountry={setIsExeceptionCountry}
        />
        {applyAuth && (
          <>
            <OtpInput
              control={control}
              name='code'
              label={t('nr_steps.verify.otp_label')}
              shouldAutoFocus={applyAuth}
              rules={{ required: { value: true, message: 'Это поле не может быть пустым' } }}
            />
            <div tw='mt-3 text-center'>
              {timerEnd ? (
                <Button variant='link' onClick={resetHandler}>
                  {t('nr_steps.verify.resend')}
                </Button>
              ) : (
                <SubBody
                  text={`${t('nr_steps.verify.resend_after')} ${minutes < 10 ? '0' + minutes : minutes}:${
                    seconds < 10 ? '0' + seconds : seconds
                  }`}
                />
              )}
            </div>
          </>
        )}
      </div>

      <div tw='bg-secondary p-5 sm:rounded-b-2xl mt-3' css={[twStyle]}>
        <Button variant='secondary' onClick={verifySubmit} disabled={recaptchaValue} loading={loading}>
          {authSuccess || invalidOTP ? t('my_documents_auth.login.send_sms') : t('my_documents_auth.login.get_sms')}
        </Button>
      </div>
    </div>
  );
};
export default LoginContent;

export const is_invest_open = Boolean(parseInt(import.meta.env.NR_INVEST_OPEN));
export const is_my_documents_open = Boolean(parseInt(import.meta.env.NR_MY_DOCUMENTS_OPEN));

export const is_prepaid_card_open = Boolean(parseInt(import.meta.env.NR_PREPAID_OPEN));
export const is_deposit_card_open = Boolean(parseInt(import.meta.env.NR_DEPOSIT_OPEN));
export const is_invest_card_open = Boolean(parseInt(import.meta.env.NR_INVEST_OPEN));

export const landing_available_for = convertToArray(import.meta.env.NR_LANDING_AVAILABLE_FOR);
export const deposit_available_for = convertToArray(import.meta.env.NR_DEPOSIT_AVAILABLE_FOR);
export const deposit_virtual_allowed = convertToArray(import.meta.env.NR_DEPOSIT_VIRTUAL_ALLOWED);
export const deposit_physical_allowed = convertToArray(import.meta.env.NR_DEPOSIT_PHYSICAL_ALLOWED);

export const invest_available_for = convertToArray(import.meta.env.NR_INVEST_AVAILABLE_FOR);
export const invest_virtual_allowed = convertToArray(import.meta.env.NR_INVEST_VIRTUAL_ALLOWED);
export const invest_physical_allowed = convertToArray(import.meta.env.NR_INVEST_PHYSICAL_ALLOWED);

export const apply_closed_text = import.meta.env.NR_CLOSED_TEXT
function convertToArray(string) {
  return string?.replaceAll(' ', '')?.split(',');
}

// console.log('is_my_documents_open', is_my_documents_open);

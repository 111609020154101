export const nr_pathnames = Object.freeze({
  landing: '/',
  info_landing: 'landing',
  landing_deposit_card: 'deposit-card-info',
  landing_invest_card: 'invest-card-info',
  landing_prepaid_card: 'prepaid-card-info',
  my_documents: 'my-documents',
  my_documents_main: '/my-documents-main',
  deposit_card: '/deposit-card',
  invest_card: '/invest-card',
  prepaid_card: '/prepaid-card',
  apply: 'apply', //1
  verify: 'verify', //2
  biometry: 'biometry', //3
  internal_checks: 'internal_checks', //4
  delivery: 'address', //5
  documents: 'documents', //6
  videocall: 'videocall', //7
  done: 'success', //8
  digital_done: 'digital-success', //9
  pp_card_type: 'pp-card-type',
  pp_success: 'pp-success',
  pp_waiting: 'pp-waiting',
  // registration_iin: 'registration_iin', //10
  existCard: 'exist-card',
  error: 'error',
  pending: 'pending',
  decode_err: 'err'
});

export const nr_steps = [
  {
    path: ['apply', 'verify'],
    title: 'Заявка'
  },
  {
    path: ['biometry'],
    title: 'Биометрия'
  },
  {
    path: ['address'],
    title: 'Получение адреса'
  },
  {
    path: ['internal_checks'],
    title: 'Проверки банка'
  },
  {
    path: ['documents', 'videocall', 'success', 'digital-success', 'pending'],
    title: 'Готово!'
  }
];

export const nr_step_statuses = Object.freeze({
  init: 'init',
  in_progress: 'in_progress',
  done: 'done'
});

export const channels = Object.freeze({
  FREEDOM_KZ: 'FREEDOM_KZ',
  TINKOFF: 'TINKOFF',
  CIFRA_BANK: 'CIFRA_BANK'
});

export const wsStatuses = Object.freeze({
  products: 'SHOW_PRODUCTS',
  payment: 'PROCESS_PAYMENT',
  creating_card: 'CREATING_CARD',
  finished: 'FINISHED',
  error: 'ERROR'
});

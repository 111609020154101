import 'twin.macro';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { isWorld } from '@/app/api/API-Url';
import BigLogo from '@/assets/images/icons/BigLogo';
import PhoneIcon from '@/assets/images/icons/PhoneIcon';
import { BodyText, Caption, ContactItem } from '@/components';
import { DeviceInfoContext } from '@/contexts/device-info-context';

import SocialMedias from './social-medias';
function Footer({ landing = true }) {
  const { isMobile } = useContext(DeviceInfoContext);
  const { t } = useTranslation();
  return (
    <div tw='py-10 sm:py-20 pb-5 lg:px-0 px-5 bg-primary transition-all' css={[!landing && tw`bg-secondary`]}>
      <div tw='max-w-app mx-auto'>
        <div tw='border-b border-grey'>
          <div tw='flex justify-between pb-20'>
            <BigLogo width='200' height='80' />
            <div>
              <div tw='flex gap-2 sm:gap-10'>
                {!isMobile && (
                  <>
                    {!isWorld && (
                      <ContactItem
                        icon={<PhoneIcon />}
                        link='tel:595'
                        text='595'
                        caption={t('info_landing.footer.free_call')}
                        iconStyle={tw`bg-secondary`}
                      />
                    )}
                  </>
                )}
                <SocialMedias />
              </div>
            </div>
          </div>
          {isMobile && (
            <div tw='flex flex-col items-center justify-between mb-5 gap-5'>
              {!isWorld && (
                <a href='tel:595'>
                  <div tw='flex flex-col items-center gap-1'>
                    <BodyText text={'595'} twStyle={tw`text-s16 font-bold`} />
                    <Caption text={t('info_landing.footer.free_call')} twStyle={tw` text-secondary`} />
                  </div>
                </a>
              )}
            </div>
          )}
        </div>

        <Caption text={t('info_landing.footer.license')} twStyle={tw`text-gray-400 mt-5 sm:text-right text-center`} />
      </div>
    </div>
  );
}

export default Footer;

/* eslint-disable react/display-name */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
import 'twin.macro';

import React from 'react';
import { forwardRef } from 'react';
import ReactDatePicker, { CalendarContainer } from 'react-datepicker';
import { useForm } from 'react-hook-form';
import tw from 'twin.macro';

import CalendarIcon from '@/assets/images/icons/CalendarIcon';
import { SubTitle } from '@/components';

function DatePickerInput({ startDate, setStartDate, endDate, setEndDate }) {
  const { control } = useForm();

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div>
      <button className='example-custom-input' tw='flex' onClick={onClick} ref={ref}>
        {value}
        <CalendarIcon />
      </button>
    </div>
  ));

  const Container = ({ className, children }) => {
    return (
      <div tw='flex flex-col sm:w-[500px] absolute bottom-4 -left-20 sm:-left-96'>
        <CalendarContainer className={className}>
          <div tw='mb-5 mt-2'>
            <SubTitle text={'Пeриод'} twStyle={tw`ml-5`} />
          </div>
          <div tw='relative'>{children}</div>
        </CalendarContainer>
      </div>
    );
  };
  return (
    <div tw='relative w-full'>
      <ReactDatePicker
        selected={startDate}
        onChange={onChange}
        popperPlacement='bottom'
        startDate={startDate}
        endDate={endDate}
        monthsShown={2}
        dateFormat={'dd/MM/yyyy'}
        maxDate={new Date()}
        calendarContainer={Container}
        selectsRange
        customInput={<ExampleCustomInput />}
      />
    </div>
  );
}

export default DatePickerInput;

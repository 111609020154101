import * as React from 'react';

function ProfileIcon(props) {
  return (
    <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g opacity={0.4} stroke='#fff' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round'>
        <path d='M12 12a5 5 0 100-10 5 5 0 000 10zM20.59 22c0-3.87-3.85-7-8.59-7s-8.59 3.13-8.59 7' fill='none' />
      </g>
    </svg>
  );
}

export default ProfileIcon;

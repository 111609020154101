/* eslint-disable react/jsx-key */
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { deposit } from './ru/deposit';
import { info_landing } from './ru/info-landing';
import { invest } from './ru/invest';
import { my_documents, my_documents_auth, my_documents_deposit } from './ru/my_documents';
import { nr_steps } from './ru/nr-steps';
import { prepaid } from './ru/prepaid';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'ru',
    resources: {
      ru: {
        translation: {
          info_landing: info_landing,
          deposit: deposit,
          invest: invest,
          prepaid: prepaid,
          nr_steps: nr_steps,
          my_documents: my_documents,
          my_documents_deposit: my_documents_deposit,
          my_documents_auth: my_documents_auth
        }
      },
      en: {
        translation: {
          header: {},
          banner: {},
          card_types: {},
          info_block_1: {},
          info_block_2: {},
          info_block_3: {},
          footer: {},
          deposit_banner: {},
          deposit_benefits: {},
          deposit_replenish: {},
          deposit_withdaw: {},
          deposit_condition: {},
          deposit_qa: {},
          deposit_info_banner: {}
        }
      }
    }
  });

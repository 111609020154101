import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { applicationApi } from '@/app/api/application';
import { setCardType } from '@/app/store/slices/app';
import { getChannel } from '@/app/store/slices/channel';
import { setCustomError } from '@/app/store/slices/error';

const useCheckStatus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState('');
  const [statusData, setStatusData] = useState(null);
  const [card, setCard] = useState(null);
  const channel = useSelector(getChannel);
  useEffect(() => {
    if (statusData?.card_product === 'DEPOSIT') {
      dispatch(setCardType('deposit-card'));
      setCard('deposit-card');
    } else if (statusData?.card_product === 'INVEST') {
      setCard('invest-card');
      dispatch(setCardType('invest-card'));
    }
  }, [statusData, dispatch, navigate]);

  // document.cookie = 'channel=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

  useEffect(() => {
    if (statusData?.current_step && statusData?.current_step !== currentStep && card) {
      setCurrentStep(statusData?.current_step);
      switch (statusData?.current_step) {
        case 'BIOMETRY':
          navigate(`/${card}/biometry`);
          break;
        case 'INTERNAL_CHECKS':
          navigate(`/${card}/internal_checks`);
          break;
        case 'ADDRESS':
          navigate(`/${card}/address`);
          break;
        case 'DOCUMENTS':
          navigate(`/${card}/documents`);
          break;
        case 'VIDEO_CALL':
          navigate(`/${card}/videocall`);
          break;
        case 'COLVIR':
          navigate(`/${card}/success`);
          break;
        default:
          break;
      }
    }
  }, [navigate, statusData, currentStep, card]);

  const checkStatus = useCallback(() => {
    dispatch(applicationApi.endpoints.getStatus.initiate(null, { forceRefetch: true }))
      .unwrap()
      .then(res => {
        setStatusData(res.data);
      })
      .catch(err => {
        if (
          String(err?.code).toLowerCase() === 'check_status' ||
          String(err?.data?.error?.code).toLowerCase() === 'pending_aml'
        ) {
          dispatch(setCustomError(err?.data?.error));
        } else if (String(err?.data?.error?.code).toLowerCase() === 'pending_need_checks') {
          navigate('/deposit-card/pending');
        } else if (String(err?.data?.error?.code).toLowerCase() === 'pending_need_checks_invest') {
          // navigate('/invest-card/pending');
        }
      });
  }, []);

  return [checkStatus];
};

export default useCheckStatus;

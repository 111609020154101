import 'twin.macro';

import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getIsAuth } from '@/app/store/slices/auth';
import { getCustomErrors } from '@/app/store/slices/error';
import BigExclamationIcon from '@/assets/images/icons/BigExclamationIcon';
import { Title } from '@/components';
import useCheckStatus from '@/helper/hooks/useCheckStatus';
import Authmodal from '@/views/auth-modal';

function PlDecodeErrorPage() {
  const [open, setOpen] = useState(false);
  const customError = useSelector(getCustomErrors);
  const isAuth = useSelector(getIsAuth);

  const [checkStatus] = useCheckStatus();

  useEffect(() => {
    customError?.code === 'AUTHORIZED_PERSONAL_LINK' && setOpen(true);
  }, [customError?.code]);

  useEffect(() => {
    isAuth && checkStatus();
  }, [isAuth, checkStatus]);

  return (
    <div tw='flex flex-col justify-center items-center gap-10 h-[100vh]'>
      <div tw='w-[144px] h-[144px] flex items-center justify-center rounded-full mx-auto bg-yellow-alpha20 mt-[48px]'>
        <BigExclamationIcon />
      </div>
      <div tw='text-center'>
        {customError?.code !== 'AUTHORIZED_PERSONAL_LINK' && <Title text={customError?.description} />}
      </div>
      {customError?.code === 'AUTHORIZED_PERSONAL_LINK' && (
        <Authmodal outsideClose={false} withCloseIcon={false} authOpen={open} setAuthOpen={setOpen} />
      )}
    </div>
  );
}

export default PlDecodeErrorPage;

import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { api } from '@/app/api';
import ProfileIcon from '@/assets/images/icons/ProfileIcon';
import { BigTitle, BodyText } from '@/components';

import MyDocumentsMobileHeader from './my-documents-mobile-header';

function MyDocumentsHeader({ userName, userLastName, mobile_phone, isMobile }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <>
      {isMobile && (
        <MyDocumentsMobileHeader mobile_phone={mobile_phone} userLastName={userLastName} userName={userName} />
      )}
      <div tw='flex justify-between items-end'>
        <BigTitle text={t('my_documents.title')} twStyle={tw`sm:text-[50px] text-[32px] text-[#70FF6E]`} />
        {!isMobile && (
          <div tw='flex gap-2 mb-2 items-end'>
            <ProfileIcon />
            {userLastName ? (
              <BodyText twStyle={tw`whitespace-nowrap`} text={userLastName.concat(' ', userName)} />
            ) : (
              <CurrencyFormat
                tw='whitespace-nowrap'
                value={mobile_phone}
                displayType={'text'}
                format='+# (###) ### ## ##'
              />
            )}
            <BodyText text={'•'} twStyle={tw`text-secondary`} />
            <button
              onClick={() => {
                navigate('/');
                dispatch(api.util.resetApiState());
              }}
            >
              <BodyText text={t('my_documents.exit')} twStyle={tw`cursor-pointer`} />
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default MyDocumentsHeader;

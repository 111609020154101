export const invest = {
  banner: {
    title: 'Карта инвестора для иностранных граждан',
    subtitle: {
      benefit1: 'Оформление за 10 минут в отделении банка',
      benefit2: '4 полноценных счёта в тенге, долларах, евро и рублях',
      benefit3: 'Доход на остаток — 3% годовых в долларах',
      benefit4: 'Привязана к счёту во Freedom Finance Global PLC'
    },
    button: 'Заказать карту',
    caption: 'Приготовьте ИИН, загранпаспорт и смартфон с камерой'
  },
  benefits: {
    title: 'Карта для инвестирования и покупок',
    card_1:
      'В процессе заказа карты вы откроете брокерский счёт во Freedom Finance Global PLC и аккаунт в приложении Tradernet',
    card_2: 'Карта привязана к 4 неторговым счетам и отражает их баланс',
    card_3: 'У каждого счёта — свой IBAN для межбанковских переводов',
    card_4: 'Разные способы пополнения карты и вывода денег',
    card_5: 'Пополнение карты и конвертации — без комиссий',
    card_6: 'Оплаты по всему миру и в интернете — без комиссий',
    card_7: 'Получение пластиковой карты в любом отделении банка',
    card_8: {
      title: '3% годовых в долларах на остаток',
      subtitle: 'Freedom Finance Global PLC ежедневно начисляет вознаграждение на остаток накопительного (D) счёта.',
      caption: 'Компания не является участником Казахстанского фонда гарантирования депозитов'
    }
  },
  hiw: {
    title: 'Как это работает',
    subtitle:
      'Брокерский счёт во Freedom Finance Global PLC состоит из двух частей — торгового и накопительного счетов:',
    card_1: {
      title: 'Торговый счёт',
      caption: 'для покупки и продажи акций международных компаний: Amazon, Facebook, Tesla и тысячи других.'
    },
    card_2: {
      title: 'Накопительный счёт',
      caption:
        'для хранения денег. Карта инвестора отражает его баланс. D-счёт можно использовать для оплаты покупок, переводов, пополнения или вывода денег с торгового счёта в долларах, тенге, евро и рублях.'
    }
  },
  replenish: {
    title: 'Удобно пополнять карту',
    container_1: {
      title: 'Банковским переводом (Свифт)',
      caption: 'На свой счёт в долларах, евро или рублях',
      p1: 'Перевод можно отправить в долларах, евро или рублях',
      p2: 'Реквизиты ваших счетов доступны в мобильном приложении Freedom Bank',
      p3: 'Лимиты и комиссии — согласно тарифов банка-отправителя.',
      p4: 'Банк оставляет за собой право запросить документы, подтверждающие источник происхождения денег.'
    },
    container_2: {
      title: 'Переводом с карты Visa или Mastercard',
      caption: 'Подойдёт любая карта Visa или Mastercard, кроме выпущенных в РФ',
      p1: 'Пополнить свою карту можно с любой карты Visa или Mastercard (кроме выпущенных в РФ) через мобильное приложение Freedom Bank в разделе «Переводы» — «С карты на карту».',
      p2: 'Лимиты на переводы с карт Visa: на один перевод — 25 000 $, сумма переводов в сутки — 50 000 $, в неделю — 100 000 $, в месяц — 200 000 $ (эквивалент в валюте перевода).',
      p3: 'Лимиты на переводы с карт Mastercard: на один перевод — 5 000 $, сумма переводов в месяц — 10 000 $ (эквивалент в валюте перевода).',
      p4: 'У нас нет комиссий на пополнение с карт. Но комиссии и ограничения могут быть у банка, выпустившего карту отправителя.'
    },
    container_5: {
      title: 'Наличными в банкомате или отделении банка',
      caption: 'на территории Казахстана',
      p1: 'Пополняйте счета в банкоматах и отделениях Freedom Bank и кассах Freedom Mobile на территории Казахстана.',
      p2: 'Банкоматы банка и кассы Freedom Mobile принимают только тенге.',
      p3: 'В отделении Банка принимают тенге и иностранную валюту.',
      p4: '',
      p5: 'Банк оставляет за собой право запросить документы, подтверждающие источник происхождения денег.'
    }
  },
  withdaw: {
    title: 'Удобно выводить деньги'
  },
  conditions: {
    title: 'Условия и тарифы',
    line1: {
      column: 'Вид карты',
      value: 'Mastercard World',
      caption: 'цифровая + пластиковая по требованию'
    },
    line2: { column: 'Валюта', value: 'тенге, доллары, евро и рубли' },
    line3: {
      column: 'Срок действия',
      value: '3 года'
    },
    line4: { column: 'Выпуск и обслуживание', value: 'бесплатно ' },
    line5: { column: 'Пополнение', value: 'бесплатно' },
    line6: { column: 'Снятие наличных', value: '3%', caption: 'минимум 500 тенге' },
    line7: { column: 'Переводы внутри банка', value: '3%' },
    line8: { column: 'Переводы на карты других банков', value: '3%', caption: 'минимум 500 тенге' },
    line9: { column: 'Конвертации', value: 'бесплатно' },
    line10: { column: 'СМС-информирование', value: 'бесплатно' },
    line11: {
      column: 'Максимальная сумма перевода c карты на карту',
      value: '5 000 долларов за один раз'
    },
    line12: {
      column: 'Максимальная сумма всех переводов с карты на карту',
      value: '100 000 долларов в месяц'
    },
    tariff: 'Полный список тарифов по карте'
  },
  qa: {
    title: 'Вопросы и ответы по карте',
    tab1: 'Открытие и получение',
    tab2: 'Использование',
    tab3: 'Инвестирование',
    tab4: 'Снятие, пополнение и переводы',
    tab5: 'Налоговые и юридические вопросы',
    tab6: 'Решение проблем'
  }
};

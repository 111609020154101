import { api } from '.';
import { APIUrl } from './API-Url';

export const applyApi = api.injectEndpoints({
  endpoints: build => ({
    documents: build.query({
      query: ({ uuid }) => ({
        url: `${APIUrl}/apply/${uuid}/documents`,
        method: 'GET'
      })
    }),
    otpVerify: build.mutation({
      query: ({ uuid, code }) => ({
        url: `${APIUrl}/apply/${uuid}/otp-verify`,
        method: 'POST',
        body: { code }
      })
    }),
    signDocuments: build.mutation({
      query: ({ uuid }) => ({
        url: `${APIUrl}/apply/${uuid}/sign-documents`,
        method: 'POST'
      })
    }),
    otpResend: build.mutation({
      query: ({ uuid }) => ({
        url: `${APIUrl}/apply/${uuid}/resend`,
        method: 'POST'
      })
    }),
    getCountriesMainPage: build.query({
      query: ({ is_visible, is_prepaid_available }) => ({
        url: `${APIUrl}/cities/countries/`,
        method: 'GET',
        params: { is_visible, is_prepaid_available }
      })
    })
  })
});

export const {
  useDocumentsQuery,
  useOtpVerifyMutation,
  useSignDocumentsMutation,
  useOtpResendMutation,
  useGetCountriesMainPageQuery
} = applyApi;

import React from 'react';

import BigCheckIcon from '@/assets/images/icons/BigCheckIcon';
import { SubTitle, Title } from '@/components';

function DeliverySuccess({ deliveryInfo }) {
  console.log('deliveryInfo', deliveryInfo);
  return (
    <div tw='bg-[#444444] p-5 py-10 rounded-2xl flex sm:flex-row flex-col gap-5 sm:items-center'>
      <div tw='w-[144px] h-[144px] flex items-center justify-center rounded-full mx-auto bg-green-alpha '>
        <BigCheckIcon />
      </div>
      <div tw='flex flex-col gap-3'>
        <Title text={'Заявка на пластиковую карту принята'} variant={'bold'} />
        <SubTitle
          text={`${
            deliveryInfo?.delivery ? 'Курьер доставит её в течение 3 дней по адресу:' : 'Заберите её по адресу:'
          }${deliveryInfo.address}`}
        />
        <SubTitle text={'Для получения понадобится загранпаспорт'} />
      </div>
    </div>
  );
}

export default DeliverySuccess;

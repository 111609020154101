import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import ProfileIcon from '@/assets/images/icons/ProfileIcon';
import { BodyText } from '@/components';

function MyDocumentsMobileHeader({ userLastName, userName, mobile_phone }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div tw='flex gap-2 mb-2 items-end'>
      <ProfileIcon />
      <BodyText
        text={
          userLastName ? (
            <BodyText text={userLastName.concat(' ', userName)} />
          ) : (
            <CurrencyFormat value={mobile_phone} displayType={'text'} format='+# (###) ### ## ##' />
          )
        }
        twStyle={tw`whitespace-nowrap`}
      />
      <BodyText text={'•'} twStyle={tw`text-secondary`} />
      <button onClick={() => navigate('/')} tw='mb-[-2px]'>
        <BodyText text={t('my_documents.exit')} />
      </button>
    </div>
  );
}

export default MyDocumentsMobileHeader;

import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';
import * as yup from 'yup';

import { useApplyAuthMutation, useSendOtpMutation } from '@/app/api/application';
import { getIsAuth, setAuth } from '@/app/store/slices/auth';
import { getChannel } from '@/app/store/slices/channel';
import { clearCustomError } from '@/app/store/slices/error';
import { Modal } from '@/components';
import useCheckStatus from '@/helper/hooks/useCheckStatus';

import LoginContent from './login';

function Authmodal({ authOpen, setAuthOpen, phone = null, twStyle, outsideClose = true, withCloseIcon = true }) {
  const [countryFormatLength, setCountryFormatLength] = useState();
  const [isExeceptionCountry, setIsExeceptionCountry] = useState(false);

  const schema = yup
    .object({
      mobile_phone: yup
        .string()
        .trim()
        .test('len', '', val => {
          if (isExeceptionCountry) {
            if (val.length !== countryFormatLength && val.length !== countryFormatLength + 1) {
              return false;
            } else {
              return true;
            }
          } else {
            return String(val).length === countryFormatLength && !(String(val).length > countryFormatLength);
          }
        })
        .required()
    })
    .required('Это поле обязательное');

  const [
    fetchSendOtp,
    { error: sendOtpError, isSuccess: sendOtpSuccess, isLoading: sendOtpLoading, reset: sendOtpReset }
  ] = useSendOtpMutation();

  const [
    fetchApplyAuth,
    { data: applyAuthData, isSuccess: applyAuthSuccess, isLoading: applyAuthLoading, error: applyAuthError }
  ] = useApplyAuthMutation();

  const [checkStatus] = useCheckStatus();
  const dispatch = useDispatch();
  const sendOtpErr = sendOtpError?.data?.error;
  const applyAuthErr = applyAuthError?.data?.error;

  const isAuth = useSelector(getIsAuth);
  const channel = useSelector(getChannel);

  const {
    control: authControl,
    watch: authWatch,
    reset: authReset,
    resetField: authResetField,
    setValue: authSetValue,
    getValues,
    setError: authSetError,
    formState: { errors },
    trigger
  } = useForm({
    mode: 'onChange',
    defaultValues: { mobile_phone: '', code: '' },
    resolver: yupResolver(schema)
  });
  const code = authWatch('code');
  const mobile_phone = authWatch('mobile_phone');

  useEffect(() => {
    if (!authOpen) {
      authReset();
      authResetField('code', 'mobile_phone');
    }
  }, [authOpen]);

  // console.log('sendOtpErr', sendOtpErr?.description);
  useEffect(() => {
    if (sendOtpErr) {
      authSetError('code', { type: 'value', message: sendOtpErr?.description });
    }
  }, [sendOtpErr, authSetError]);

  useEffect(() => {
    if (applyAuthErr) {
      authSetError('code', { type: 'value', message: applyAuthErr?.description });
    }
  }, [applyAuthErr, authSetError]);

  useEffect(() => {
    if (sendOtpErr?.code === 'NO_ACTIVE_APPLICATION') {
      authSetError('mobile_phone', { type: 'value', message: 'У Вас нет активных заявок' });
    } else if (sendOtpErr?.code === 'NO_ACTIVE_ACCOUNT') {
      authSetError('mobile_phone', { type: 'value', message: 'Учетная запись не найдена' });
    } else if (sendOtpErr?.code === 'INVALID_DATA') {
      authSetError('mobile_phone', { type: 'value', message: 'Введите корректный номер телефона' });
    }
  }, [sendOtpErr, authSetError]);

  useEffect(() => {
    if (code?.length === 4) {
      if (mobile_phone) {
        const withPlus = '+' + mobile_phone;
        document.cookie = `channel=${channel}; max-age=300`;
        fetchApplyAuth({ code, mobile_phone: withPlus });
      }
    }
  }, [code, mobile_phone, fetchApplyAuth]);

  const sendOtp = useCallback(() => {
    let phone = '';
    if (!mobile_phone.includes('+')) {
      phone = '+' + mobile_phone?.replaceAll(' ', '');
    } else {
      phone = mobile_phone.replaceAll(' ', '');
    }

    if (phone?.length >= 12 && (!applyAuthSuccess || !isAuth)) {
      fetchSendOtp({ mobile_phone: phone });
    }
  }, [mobile_phone, applyAuthSuccess, fetchSendOtp]);

  const handleResendOtp = useCallback(() => {
    const phone = '+' + mobile_phone?.replaceAll(' ', '');
    fetchSendOtp({ mobile_phone: phone });
  }, [mobile_phone, fetchSendOtp]);

  useEffect(() => {
    if (applyAuthSuccess) {
      dispatch(clearCustomError());
      dispatch(setAuth(applyAuthData?.data));
      checkStatus();
      setAuthOpen(false);
    }
  }, [applyAuthSuccess, applyAuthData, dispatch, checkStatus]);

  return (
    <div>
      <Modal
        outsideClose={outsideClose}
        withCloseIcon={withCloseIcon}
        title={'Вернуться в заявку'}
        open={authOpen}
        setOpen={setAuthOpen}
        twStyle={tw`!p-0 bg-primary max-w-[460px] rounded-2xl text-left`}
        headerStyle={tw`p-5 bg-secondary rounded-t-2xl mb-3 flex items-center justify-between`}
      >
        <LoginContent
          phone={phone}
          trigger={trigger}
          isExeceptionCountry={isExeceptionCountry}
          setIsExeceptionCountry={setIsExeceptionCountry}
          control={authControl}
          authSuccess={sendOtpSuccess}
          verifySubmit={sendOtp}
          recaptchaValue={''}
          loading={sendOtpLoading}
          reset={authResetField}
          authReset={handleResendOtp}
          sendOtpError={sendOtpError}
          errors={errors}
          setValue={authSetValue}
          authOpen={authOpen}
          setCountryFormatLength={setCountryFormatLength}
          getValues={getValues}
        />
      </Modal>
    </div>
  );
}

export default Authmodal;

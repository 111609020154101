/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import 'twin.macro';

import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useClickAway } from 'react-use';
import tw from 'twin.macro';

import {
  deposit_available_for,
  invest_available_for,
  is_my_documents_open,
  is_prepaid_card_open
} from '@/app/availlability_vars';
import { getChannel } from '@/app/store/slices/channel';
import FfinLogo from '@/assets/images/ffin-logo';
import DropDownIcon from '@/assets/images/icons/DropDownIcon';
import LeftBlick from '@/assets/landing-banner-left-blick.svg';
import RightBlick from '@/assets/landing-banner-right-blick.svg';
import { DeviceInfoContext } from '@/contexts/device-info-context';

import Authmodal from '../auth-modal';
import MobileHeader from './mobile-header';

const langs = [
  {
    value: 'ru',
    title: 'Рус'
  },
  {
    value: 'en',
    title: 'Eng'
  }
];

const menu = {
  open: { width: '100vw', transition: '0.5s', height: '100vh' },
  close: { width: '0', transition: '0.5s', height: '0', display: 'hidden' }
};
const nav = {
  open: { width: '100vw', transition: '1s' },
  close: { width: '0', transition: 's', display: 'none' }
};
function LandingHeader({ isHeader }) {
  const { t } = useTranslation();
  const { isMobile } = useContext(DeviceInfoContext);
  const [open, setOpen] = useState(false);
  const [ddOpen, setDdOpen] = useState(false);
  const location = useLocation().pathname;
  const channel = useSelector(getChannel);

  const ref = useRef();

  useClickAway(ref, () => {
    setDdOpen(false);
  });

  return (
    <div tw=''>
      <img src={LeftBlick} alt='' tw='absolute top-0 left-0 sm:w-auto w-[200px]' />
      <img src={RightBlick} alt='' tw='absolute top-0 right-0 sm:w-auto w-[200px]' />

      {isMobile ? (
        <MobileHeader isHeader={isHeader} />
      ) : (
        <>
          <div tw='w-full flex justify-between items-center '>
            <a href='/'>
              <FfinLogo />
            </a>

            <div tw='flex items-center gap-8 z-20'>
              <nav tw='flex justify-between gap-8 xl:text-s16 sm:text-s12'>
                {/* {isHeader && (
                  <a href='/' tw='py-2' css={[location === '/' && tw`font-bold`]}>
                    {t('info_landing.header.main')}
                  </a>
                )} */}

                {/* {isHeader && (
                  <button onMouseOver={() => setDdOpen(true)} ref={ref} tw='relative w-auto'>
                    <AnimatePresence>
                      <motion.div
                        initial={{ width: '100px', display: 'flex' }}
                        animate={
                          ddOpen
                            ? {
                                width: '190px',
                                display: 'flex',
                                backgroundColor: '#1a1a1a'
                              }
                            : { width: '100px', display: 'flex' }
                        }
                        exit={{ width: '100px' }}
                        transition={{ duration: 0.3 }}
                        tw='px-2 py-2 rounded-xl flex items-start justify-between'
                      >
                        <span> {t('info_landing.header.cards')}</span>
                        <span css={[ddOpen && tw`rotate-180`]} tw='2xl:mt-0 xl:mt-0 lg:-mt-1 md:-mt-1'>
                          <DropDownIcon color={ddOpen ? '#70FF6E' : '#B7BBBD'} />
                        </span>
                      </motion.div>
                    </AnimatePresence>
                    <AnimatePresence>
                      {ddOpen && (
                        <motion.div
                          initial={{ height: 0, position: 'absolute', top: '40px' }}
                          animate={{ height: 'auto' }}
                          exit={{ height: 0 }}
                          transition={{ duration: 0.5 }}
                          tw='overflow-hidden absolute top-0'
                        >
                          <div tw='min-w-[190px] text-left  bg-primary rounded-xl flex flex-col'>
                            {deposit_available_for?.find(item => item === channel) && (
                              <a
                                href='deposit-card-info'
                                css={[location === '/deposit-card-info' && tw`font-bold`]}
                                tw='hover:bg-[rgba(183, 187, 189,0.2)] transition-colors py-2 px-3 rounded-t-xl'
                              >
                                {t('info_landing.header.deposit')}
                              </a>
                            )}
                            {invest_available_for?.find(item => item === channel) && (
                              <a
                                href='invest-card-info'
                                tw='hover:bg-[rgba(183, 187, 189,0.2)] transition-colors py-2 px-3'
                                css={[location === '/invest-card-info' && tw`font-bold`]}
                              >
                                {t('info_landing.header.invest')}
                              </a>
                            )}
                            {is_prepaid_card_open && channel !== 'UZBEKISTAN' && (
                              <a
                                href='prepaid-card-info'
                                tw='hover:bg-[rgba(183, 187, 189,0.2)]  transition-colors py-2 px-3 rounded-b-xl'
                                css={[location === '/prepaid-card-info' && tw`font-bold`]}
                              >
                                {t('info_landing.header.prepaid')}
                              </a>
                            )}
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </button>
                )}

                {Boolean(is_my_documents_open) && (
                  <a
                    tw='py-2'
                    href='my-documents'
                    css={[(location === '/my-documents' || location === '/my-documents-main') && tw`font-bold`]}
                  >
                    {t('info_landing.header.my_documents')}
                  </a>
                )}
                {isHeader && (
                  <span
                    onClick={() => {
                      setOpen(true);
                    }}
                    tw='cursor-pointer py-2'
                  >
                    {t('info_landing.header.status')}
                  </span>
                )} */}

                {/* <Selector items={langs} defaultActiveItem={'ru'} /> */}
              </nav>
            </div>
          </div>
        </>
      )}
      <Authmodal authOpen={open} setAuthOpen={setOpen} />
    </div>
  );
}

export default LandingHeader;

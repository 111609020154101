import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getStep } from '@/app/store/slices/app';
import { wsStatuses } from '@/app/utils/constants';

export const useNavigateToTheStep = () => {
  const currentStep = useSelector(getStep);
  const navigate = useNavigate();

  console.log('currentStep', currentStep);
  const navigateToNewStep = () => {
    switch (currentStep) {
      case wsStatuses.products:
        navigate('/prepaid-card/pp-card-type');

        break;
      default:
        break;
    }
  };
  return [navigateToNewStep];
};

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const pickUpSchema = yup.object({
  addressRadio: yup.string().required()
});

export const usePickUpForm = () => {
  const {
    control,
    formState: { isValid },
    watch,
    setValue,
    getValues,
    reset
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      addressRadio: ''
    },
    resolver: yupResolver(pickUpSchema)
  });

  return {
    control,
    isValid,
    watch,
    setValue,
    getValues,
    reset
  };
};

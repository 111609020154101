import 'twin.macro';

import React, { useContext } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDebounce, usePreviousDistinct } from 'react-use';
import tw from 'twin.macro';

import { useGetCitiesQuery, useGetCountriesQuery, usePickUpAdressesQuery } from '@/app/api/delivery';
import { useCreatePhysicalCardMutation } from '@/app/api/my-documents';
import BigExclamationIcon from '@/assets/images/icons/BigExclamationIcon';
import { BodyText, Button, Input2, Modal, Select, SubBody } from '@/components';
import BoxToggle from '@/components/ui/box-toggle/box-toggle';
import { DeviceInfoContext } from '@/contexts/device-info-context';
import { dataToCities, dataToCountries, options } from '@/helper';
import { useDeliveryForm } from '@/helper/hooks/useDeliveryForm';
import { usePickUpForm } from '@/helper/hooks/usePickUpForm';
import { dataToAddressOptions, fetchGeoCode } from '@/views/delivery-form';
import PickupForm from '@/views/pickup-form';
import { YandexMap } from '@/views/yandex-map';

const filterCountries = countries => {
  const newArray = countries?.filter(item => item.has_card_physical);
  return newArray;
};

export const fetchSuggestions = async (ymaps, address) => {
  const result = await ymaps.suggest(`${address}`, { results: 5 });
  return dataToAddressOptions(result);
};

function DepositDelivery({ setCreateCardSuccess, setDeliveryInfo }) {
  const {
    control: deliveryControl,
    watch: deliveryWatch,
    setValue: deliverySetValue,
    setError: deliverySetError,
    clearErrors,
    resetField: deliveryResetField,
    getValues: deliveryGetValues,
    isValid: deliveryIsValid
  } = useDeliveryForm({});

  const {
    control: controlPickUp,
    watch: pickupWatch,
    getValues: pickupGetValues,
    setValue: pickUpSetValues,
    reset: pickUpReset,
    isValid: pickUpIsValid
  } = usePickUpForm();

  const { control, watch, setValue, setError, getValues, resetField } = useForm({});

  const [activeTab, setActiveTab] = useState('left');
  const [open, setOpen] = useState(false);
  const [revert, setRevert] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [debouncedAddress, setDebouncedAddress] = React.useState('');
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [map, setMap] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(watch('country'));
  const city = watch('city');
  const { isMobile } = useContext(DeviceInfoContext);
  const { data: countriesData, isLoading } = useGetCountriesQuery({ has_card_physical: true });
  const { data: deliveryCities } = useGetCitiesQuery({ country: selectedCountry });
  const [fetchCreatePhysicalCard, { isSuccess, error }] = useCreatePhysicalCardMutation();
  const { data: pickUpAddresses } = usePickUpAdressesQuery({ city }, { skip: !selectedCity });

  const countries = dataToCountries(filterCountries(countriesData?.data));
  //   const cities = dataToCities(data);

  const prevCity = usePreviousDistinct(selectedCity?.uuid, (prev, next) => (prev && prev) === next);
  const address = deliveryWatch('address');

  const [, cancel] = useDebounce(
    () => {
      setDebouncedAddress(address);
    },
    750,
    [address]
  );

  const lat = deliveryWatch('lat');
  const prevLat = usePreviousDistinct(lat, (prev, next) => (prev && prev) === next);
  const lon = deliveryWatch('lon');
  const prevLon = usePreviousDistinct(lon, (prev, next) => (prev && prev) === next);

  const getMap = useCallback(ymaps => {
    setMap(ymaps);
  }, []);

  useEffect(() => {
    error && setOpen(true);
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      setCreateCardSuccess(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (selectedCountry !== watch('country')) {
      resetField('city');
      setSelectedCountry(watch('country'));
    }
  }, [watch('country')]);

  useEffect(() => {
    setSelectedCity(deliveryCities?.data?.find(item => item.uuid === watch('city')));
  }, [watch('city')]);

  useEffect(() => {
    if (selectedAddress === address) {
      setAddresses([]);
    }
  }, [selectedAddress, address]);

  useEffect(() => {
    if (prevCity !== selectedCity?.uuid) {
      setSelectedAddress(null);
      deliveryResetField('address');
      deliveryResetField('entrance');
      deliveryResetField('flat');

      deliverySetValue('city', selectedCity?.uuid, { shouldValidate: true });
    }
  }, [selectedCity?.uuid, prevCity, deliverySetValue]);

  useEffect(() => {
    if (map && selectedAddress) {
      fetchGeoCode(map, selectedAddress).then(address => {
        if (address) {
          if (!address.building) {
            setError('address', { type: 'manual', message: 'Укажите номер дома' });
          } else {
            clearErrors('address');
          }
          deliverySetValue('street', address.district ? address.district + ' ' : '' + address.street, {
            shouldValidate: true
          });
          deliverySetValue('building', address.building, { shouldValidate: true });
          deliverySetValue('lat', address.coords[0], { shouldValidate: true });
          deliverySetValue('lon', address.coords[1], { shouldValidate: true });
        }
        setAddresses([]);
      });
    }
  }, [deliverySetValue, selectedAddress, selectedCity, map]);

  useEffect(() => {
    if (map && debouncedAddress && prevCity !== selectedCity?.uuid && selectedAddress !== address) {
      fetchSuggestions(map, `город ${selectedCity?.name}, ${debouncedAddress}`).then(suggestions => {
        setAddresses(suggestions);
      });
    }
  }, [debouncedAddress, selectedCity, prevCity, selectedAddress, address, map]);

  useEffect(() => {
    if (lat && lon && lat !== prevLat && lon !== prevLon && revert) {
      fetchGeoCode(map, [lat, lon], 'revert').then(address => {
        if (address) {
          deliverySetValue('address', address.address);
          setSelectedAddress(address.address);
        }
      });
    }
  }, [lat, prevLon, lon, prevLat, deliverySetValue, map, revert]);

  useEffect(() => {
    if (map && prevCity !== selectedCity?.uuid && !address) {
      fetchGeoCode(map, selectedCity?.name).then(address => {
        if (address) {
          deliverySetValue('lat', address.coords[0]);
          deliverySetValue('lon', address.coords[1]);
        }
      });
    }
  }, [selectedCity, prevCity, deliverySetValue, map, address]);

  const handleSetAddress = useCallback(
    address => {
      deliverySetValue('address', address);
      setSelectedAddress(address);
      setRevert(false);
    },
    [deliverySetValue]
  );

  const geoCoords = useMemo(() => {
    if (lat && lon) {
      return [lat, lon];
    }
  }, [lat, lon]);

  const formSetCoords = useCallback(
    coords => {
      deliverySetValue('lat', coords[0]);
      deliverySetValue('lon', coords[1]);
      setRevert(true);
    },
    [deliverySetValue]
  );

  const returnForm = () => {
    if (selectedCity?.has_delivery && !selectedCity?.has_pickup) {
      return (
        <div tw='flex flex-col gap-3 '>
          <SubBody
            text={'Курьер доставит карту в течение 3 рабочих дней. Укажите адрес доставки:'}
            twStyle={tw`px-2`}
          />
          <Input2
            name='address'
            label=''
            placeholder='Микрорайон, Улица, дом'
            control={deliveryControl}
            isRequired={false}
          />
          <div tw='relative'>
            {addresses?.length > 0 && (
              <div tw='absolute top-[0.2rem] left-0 right-0 z-50 bg-secondary pb-2 rounded-2xl'>
                {addresses?.map((address, i) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                  <div
                    key={i}
                    onClick={() => {
                      handleSetAddress(address);
                    }}
                    tw='flex items-center justify-between w-full px-4 py-3  text-sm text-primary bg-secondary rounded-2xl cursor-pointer hover:bg-primary hover:text-primary'
                  >
                    <span>{address}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div tw='flex gap-3'>
            <Input2
              label='Введите квартиру'
              name='flat'
              placeholder='Квартира/Офис'
              control={deliveryControl}
              rules={{ required: { value: true, message: 'Это поле не может быть пустым' } }}
              clearErrors={''}
              isRequired={false}
            />
            <Input2
              label='Введите подъезд'
              name='entrance'
              placeholder='Подъезд'
              control={deliveryControl}
              rules={{ required: { value: true, message: 'Это поле не может быть пустым' } }}
              clearErrors={''}
            />
          </div>
        </div>
      );
    } else if (!selectedCity?.has_delivery && selectedCity?.has_pickup) {
      return (
        <PickupForm
          handleSubmit={''}
          registrationIsSuccess={''}
          control={controlPickUp}
          watch={pickupWatch}
          getValues={pickupGetValues}
          setValue={pickUpSetValues}
          city={selectedCity?.uuid}
        />
      );
    } else {
      return (
        <SubBody
          twStyle={tw`text-center`}
          text={'К сожалению вы не можете открыть пластиковую карту в выбранном городе'}
        />
      );
    }
  };
  const handleClick = () => {
    const country = getValues('country');
    const deliveryAddress = deliveryGetValues();
    const pickUpAddress = pickupGetValues('addressRadio');
    if (deliveryAddress.address !== '') {
      setDeliveryInfo({ delivery: true, address: '' });
      fetchCreatePhysicalCard({ country: country, delivery_address: deliveryAddress });
    } else {
      fetchCreatePhysicalCard({ country: country, pickup_address: pickUpAddress });
      setDeliveryInfo({ delivery: false, address: '' });
    }
  };

  return (
    <div tw='sm:grid grid-cols-2 gap-10 items-start'>
      <div tw='flex flex-col gap-3 mt-3 sm:max-w-[400px]'>
        <div tw='relative z-40'>
          <Select options={countries} control={control} name={'country'} placeholder={'Страна'} />
        </div>
        <Select
          options={dataToCities(deliveryCities?.data)}
          control={control}
          name={'city'}
          placeholder={'Город'}
          disabled={!watch('country')}
        />
        {watch('city') && (
          <>
            {selectedCity?.has_delivery && selectedCity?.has_pickup ? (
              <>
                <BoxToggle currentTab={activeTab} setCurrentTab={setActiveTab} options={options} />
                {activeTab === 'left' ? (
                  <div tw='flex flex-col gap-3 '>
                    <SubBody
                      text={'Курьер доставит карту в течение 2 рабочих дней. Укажите адрес доставки:'}
                      twStyle={tw`px-2`}
                    />
                    <Input2
                      name='address'
                      label=''
                      placeholder='Микрорайон, Улица, дом'
                      control={deliveryControl}
                      isRequired={false}
                    />
                    <div tw='relative'>
                      {addresses?.length > 0 && (
                        <div tw='absolute top-[0.2rem] left-0 right-0 z-50 bg-secondary pb-2 rounded-2xl'>
                          {addresses?.map((address, i) => (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                            <div
                              key={i}
                              onClick={() => {
                                handleSetAddress(address);
                              }}
                              tw='flex items-center justify-between w-full px-4 py-3  text-sm text-primary bg-secondary rounded-2xl cursor-pointer hover:bg-primary hover:text-primary'
                            >
                              <span>{address}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div tw='flex gap-3'>
                      <Input2
                        label='Введите квартиру'
                        name='flat'
                        placeholder='Квартира/Офис'
                        control={deliveryControl}
                        rules={{ required: { value: true, message: 'Это поле не может быть пустым' } }}
                        clearErrors={''}
                        isRequired={false}
                      />
                      <Input2
                        label='Введите подъезд'
                        name='entrance'
                        placeholder='Подъезд'
                        control={deliveryControl}
                        rules={{ required: { value: true, message: 'Это поле не может быть пустым' } }}
                        clearErrors={''}
                      />
                    </div>
                  </div>
                ) : (
                  <PickupForm
                    twStyle={tw`!bg-[#444444]`}
                    handleSubmit={''}
                    registrationIsSuccess={''}
                    control={controlPickUp}
                    watch={pickupWatch}
                    getValues={pickupGetValues}
                    setValue={pickUpSetValues}
                    city={selectedCity?.uuid}
                    rbStyle={tw`!bg-none`}
                  />
                )}
              </>
            ) : (
              returnForm()
            )}
          </>
        )}
        {watch('city') && (selectedCity?.has_pickup || selectedCity?.has_delivery) && isMobile && (
          <div tw=''>
            <YandexMap getCoords={getMap} geoCoords={geoCoords} formSetCoords={formSetCoords} />
          </div>
        )}
        {(selectedCity?.has_pickup || selectedCity?.has_delivery) && (
          <Button variant={'ghost'} twStyle={tw`rounded-md`} onClick={handleClick}>
            Заказать
          </Button>
        )}
      </div>
      {watch('city') && (selectedCity?.has_pickup || selectedCity?.has_delivery) && !isMobile && (
        <span tw='min-w-[300px] min-h-[300px] mt-5 mr-5 '>
          <YandexMap getCoords={getMap} geoCoords={geoCoords} formSetCoords={formSetCoords} />
        </span>
      )}
      <Modal open={open} setOpen={setOpen} twStyle={tw`rounded-2xl max-w-max`}>
        <div tw='w-[104px] h-[104px] flex items-center justify-center rounded-full mx-auto bg-yellow-alpha20 mb-10'>
          <BigExclamationIcon />
        </div>
        <div tw='text-center'>
          <BodyText text={error?.data?.error?.description} variant={'bold'} twStyle={tw``} />
        </div>
      </Modal>
    </div>
  );
}

export default DepositDelivery;

import 'twin.macro';

import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';

import DatePickerInput from '@/views/date-picker';

import { BodyText } from '../ui/body-text/body-text';
import { Button } from '../ui/button/button';
import { Radio } from '../ui/radio/radio';
import { Select } from '../ui/select/select';
import { Selector2 } from '../ui/selector2/selector2';
import { SubBody } from '../ui/sub-body/sub-body';

function DocumentContainer({
  document,
  control,
  handleCheckGetActiveItem,
  setEndDate,
  setStartDate,
  endDate,
  startDate,
  accounts,
  bankDocuments,
  checkIsValid,
  handleClickButton,
  watch,
  card_type
}) {
  const [selectors, setSelectors] = useState();

  function filterUniqueObjects(inputArray) {
    const uniqueTitles = new Set();
    const resultArray = [];

    for (const obj of inputArray) {
      if (!uniqueTitles.has(obj.title)) {
        uniqueTitles.add(obj.title);
        resultArray.push(obj);
      }
    }

    return resultArray;
  }

  useEffect(() => {
    if (document?.withToggler) {
      setSelectors(
        String(watch(document.documentType)).includes('DEPO')
          ? filterUniqueObjects(accounts[0].depo)
          : filterUniqueObjects(accounts[1].client)
      );
    }
  }, [watch(document.documentType)]);

  return (
    <div tw='bg-[#444444] p-8 rounded-2xl flex flex-col h-[100%] justify-between'>
      <div tw='flex flex-col gap-3 mb-5'>
        {document?.icon}

        <BodyText text={document?.title} />
        {document?.stickerText && (
          <span tw='bg-[rgba(147, 147, 147, 0.2)] rounded-xl px-2 h-[20px] text-s14 max-w-max'>
            {document?.stickerText}
          </span>
        )}
        <SubBody text={document?.caption} twStyle={tw`text-secondary`} />
      </div>

      <div>
        {document?.radios?.length > 0 && (
          <div tw='mb-2'>
            <SubBody text={'Счет'} twStyle={tw`text-secondary`} />
            <Radio
              twStyle={tw`!bg-[#444444] p-0`}
              options={document?.radios}
              control={control}
              name={`${document?.documentType}_${card_type}`}
              containerStyle={tw`mt-2 flex xl:flex-col md:flex-row sm:flex-row`}
            />
          </div>
        )}

        {document?.withToggler && (
          <div tw='mb-5'>
            <Selector2
              textStyle={tw`text-s12 font-normal`}
              defaultActiveItem={
                document.documentType === 'REQUISITES' || document.documentType === 'HELP_ON_ACCOUNTS_AND_BALANCES'
                  ? 'rus'
                  : selectors?.length > 0 && selectors[0]?.value
              }
              items={
                document.documentType === 'REQUISITES' || document.documentType === 'HELP_ON_ACCOUNTS_AND_BALANCES'
                  ? document.selects
                  : selectors ?? []
              }
              doc={document.documentType}
              getActiveItem={handleCheckGetActiveItem(document.documentType)}
            />
          </div>
        )}
        {document?.withDate && (
          <>
            <SubBody text={'Период'} twStyle={tw`text-secondary`} />
            <div tw='mb-5 mt-2 relative z-30'>
              <DatePickerInput
                setEndDate={setEndDate}
                endDate={endDate}
                startDate={startDate}
                setStartDate={setStartDate}
              />
            </div>
          </>
        )}
        {document?.withDropDown && (
          <div tw='mb-5'>
            <Select
              control={control}
              name={'documents'}
              options={bankDocuments}
              tw=''
              placeholder={'Выберите документ'}
            />
          </div>
        )}
        <div tw=''>
          <Button
            variant={'ghost'}
            twStyle={tw`rounded-md !min-w-[120px] !max-w-[200px] `}
            disabled={checkIsValid(document?.documentType)}
            onClick={() => handleClickButton(document)}
          >
            {document?.documentType === 'BANK_DOCS'
              ? watch('documents')?.includes('api/v1/')
                ? 'Скачать'
                : document?.buttonText
              : document?.buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DocumentContainer;

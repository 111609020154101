import { api } from '.';
import { APIUrl } from './API-Url';

export const shortLinkApi = api.injectEndpoints({
  endpoints: build => ({
    shortLinkDecode: build.mutation({
      query: ({ token }) => ({
        url: `${APIUrl}/short-link/decode/`,
        method: 'POST',
        body: { token }
      })
      //   transformResponse: response => response.data
    }),
    personalShortLinkDecode: build.mutation({
      query: ({ token }) => ({
        url: `${APIUrl}/short-link/decode-personal/`,
        method: 'POST',
        body: { token }
      })
    }),
    createShortLink: build.mutation({
      query: () => ({
        url: `${APIUrl}/short-link/create/`,
        method: 'POST'
      })
    })
  })
});

export const { useShortLinkDecodeMutation, usePersonalShortLinkDecodeMutation, useCreateShortLinkMutation } =
  shortLinkApi;

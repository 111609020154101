export const prepaid = {
  banner: {
    title: 'Предоплаченные карты',
    subtitle: {
      benefit1: 'Оформление онлайн за 5 минут без посредников',
      benefit2: 'Моментальный выпуск цифровой карты',
      benefit3: 'Номиналы в 50, 100 и 200 долларов'
    },
    button: 'Купить карту'
  },
  hiw: {
    title: 'Как работает карта',
    subtitile1:
      'Предоплаченная карта открывается без документов и счёта. Ей можно оплатить покупки в магазине или подписку в интернете',
    subtitile2: 'Можно подарить другу. Когда закончатся деньги — карта закроется автоматически.',
    benefits: {
      benefit1: 'Оплаты по всему миру и в интернете, переводы по картам временно недоступны',
      benefit2: 'Есть CVC и 3D-secure, работает в электронных кошельках',
      benefit3: 'Карту можно подарить, для её открытия не нужны документы',
      benefit4: 'Пополнение или снятие денег с карты невозможны'
    }
  },
  conditions: {
    title: 'Условия и тарифы',
    line1: {
      column: 'Вид карты',
      value: 'Неименная Mastercard World',
      caption: 'цифровая'
    },
    line2: {
      column: 'Выпуск',
      value: {
        first: {
          title: '3 000 тенге',
          caption: '+ 50 долларов'
        },
        second: {
          title: '5 000 тенге',
          caption: '+ 100 долларов'
        },
        third: {
          title: '7 000 тенге',
          caption: '+ 200 долларов'
        }
      }
    },
    line3: {
      column: 'Валюта',
      value: 'доллары'
    },
    line4: { column: 'Срок действия', value: '6 месяцев с момента выпуска ' },
    line5: { column: 'Оплаты в торговых точках и интернете', value: 'без комиссии' },
    line6: { column: 'Переводы на карты других банков', value: '1%', caption: 'минимум 250 тенге' },
    line7: { column: 'Пополнения', value: 'нет' },
    line8: { column: 'Конвертации', value: 'нет' },
    line9: { column: 'Снятие наличных', value: 'нет' }
  }
};

export const my_documents_prepaid = {
  selector: {
    active: 'Активные',
    inactive: 'Закрытые'
  },
  card: {
    title: 'Карта',
    card_number: 'Номер карты и CVV',
    ordering: 'Выписка'
  }
};

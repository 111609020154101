import { useEffect } from 'react';
import { useState } from 'react';
import { GeolocationControl, Map, Placemark, YMaps } from 'react-yandex-maps';

const apikey = import.meta.env.NR_YANDEX_API_KEY;

export const YandexMap = ({ getCoords, geoCoords, formSetCoords, twStyle }) => {
  const [map, setMap] = useState(null);
  const [coords, setCoords] = useState([43.246106, 76.945298]);

  useEffect(() => {
    if (geoCoords) {
      setCoords(geoCoords);
    }
  }, [geoCoords]);

  const mapState = {
    center: coords,
    zoom: 14,
    controls: ['zoomControl']
  };

  const onMapLoad = ymaps => {
    getCoords(ymaps);
    setMap(ymaps);
  };

  const onMapClick = e => {
    const coords = e.get('coords');
    setCoords(coords);
    formSetCoords(coords);
  };

  return (
    <YMaps query={{ apikey, lang: 'ru_RU', suggest_apikey: 'f8b5a5f6-fb25-41af-bdb3-209ebe05349e' }} css={[twStyle]}>
      <Map
        state={mapState}
        width='100%'
        height='400px'
        modules={['control.ZoomControl', 'geocode', 'suggest']}
        onLoad={onMapLoad}
        onClick={onMapClick}
        onError={e => console.log('MAP ERROR: ', e)}
      >
        {map && (
          <GeolocationControl
            options={{
              float: 'left'
            }}
          />
        )}
        {map && <Placemark geometry={coords} />}
      </Map>
    </YMaps>
  );
};

import { api } from '.';
import { APIUrl } from './API-Url';

const deliveryApi = api.injectEndpoints({
  endpoints: build => ({
    getCities: build.query({
      query: ({ country, delivery }) => ({
        url: `${APIUrl}/delivery/cities/`,
        method: 'GET',
        params: { country, delivery }
      })
    }),
    getCountries: build.query({
      query: ({ has_card_physical, has_card_virtual }) => ({
        url: `${APIUrl}/delivery/countries/`,
        method: 'GET',
        params: { has_card_physical, has_card_virtual }
      })
    }),
    registrationAdress: build.mutation({
      query: ({ city, street, building, flat, entrance, country, region = '' }) => ({
        url: `${APIUrl}/delivery/registration-address/`,
        method: 'POST',
        body: { city, street, building, flat, entrance, country, region }
      })
    }),
    pickUpAdresses: build.query({
      query: ({ city, limit, offset }) => ({
        url: `${APIUrl}/delivery/pickup-adresses/`,
        method: 'GET',
        params: { city, limit, offset }
      })
    }),
    setAddress: build.mutation({
      query: ({
        pickup_address = null,
        delivery_address = null,
        live_address_same_as_registration = true,
        live_address = null,
        card_type,
        country
      }) => ({
        url: `${APIUrl}/delivery/delivery/`,
        method: 'POST',
        body: { pickup_address, delivery_address, live_address_same_as_registration, live_address, card_type, country }
      })
    })
  })
});

export const {
  useGetCitiesQuery,
  useGetCountriesQuery,
  useRegistrationAdressMutation,
  usePickUpAdressesQuery,
  useSetAddressMutation
} = deliveryApi;

import { api } from '.';
import { APIUrl } from './API-Url';

export const applicationApi = api.injectEndpoints({
  endpoints: build => ({
    apply: build.mutation({
      query: ({ iin, mobile_phone, country, channel, is_with_iin_creation, card_product }) => ({
        url: `${APIUrl}/application/apply/`,
        method: 'POST',
        body: { iin, mobile_phone, citizenship: country, channel, is_with_iin_creation, card_product }
      })
      //   transformResponse: response => response.data
    }),
    verify: build.mutation({
      query: ({ lead_uuid, code }) => ({
        url: `${APIUrl}/application/${lead_uuid}/verify/`,
        method: 'POST',
        body: { code },
        credentials: 'include'
      })
    }),
    getStatus: build.query({
      query: () => ({
        url: `${APIUrl}/application/status/`,
        method: 'GET'
      })
    }),
    resendOtp: build.mutation({
      query: ({ lead_uuid }) => ({
        url: `${APIUrl}/application/${lead_uuid}/resend/`,
        method: 'POST'
      })
    }),
    applyAuth: build.mutation({
      query: ({ code, mobile_phone }) => ({
        url: `${APIUrl}/application/apply-auth/`,
        method: 'POST',
        body: { code, mobile_phone }
      })
    }),
    sendOtp: build.mutation({
      query: ({ mobile_phone }) => ({
        url: `${APIUrl}/application/send-otp/`,
        method: 'POST',
        body: { mobile_phone }
      })
    }),
    cancelApplication: build.mutation({
      query: () => ({
        url: `${APIUrl}/application/cancel-application/`,
        method: 'POST'
      })
    })
  })
});

export const {
  useApplyMutation,
  useVerifyMutation,
  useGetStatusQuery,
  useResendOtpMutation,
  useApplyAuthMutation,
  useSendOtpMutation,
  useCancelApplicationMutation
} = applicationApi;

import React from 'react';
import { useCallback } from 'react';
import tw from 'twin.macro';

import BucketIcon from '@/assets/images/icons/BucketIcon';
import TransferIcon from '@/assets/images/icons/TransferIcon';
import { BodyText, SubBody } from '@/components';

function TransactionList({ transactions, dates, filterTransactions, filter }) {
  const convertDate = useCallback(date => {
    if (new Date(date).toLocaleDateString() === new Date().toLocaleDateString()) {
      return 'Сегодня';
    } else {
      const formattedDate = new Date(date).toLocaleString('ru-RU', {
        day: 'numeric',
        month: 'long',
        timeZone: 'UTC'
      });
      return formattedDate;
    }
  }, []);
  return (
    <>
      {dates?.map(date => {
        return (
          <div key={date} tw='text-left'>
            <SubBody text={convertDate(date)} twStyle={tw`text-secondary`} />
            <div tw='flex flex-col divide-grey divide-y'>
              {filterTransactions(new Date(date), transactions)?.map(item => (
                <div tw='flex py-5 w-full justify-between gap-5' key={item?.transaction_id}>
                  <div tw='flex gap-2'>
                    {item?.incfl_name === 'списание' ? <BucketIcon /> : <TransferIcon />}
                    <div tw='flex flex-col gap-3 text-left'>
                      <BodyText text={item?.operation_name} />
                      <SubBody
                        text={new Date(item?.time).toLocaleTimeString([], {
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: false
                        })}
                        twStyle={tw`text-secondary`}
                      />
                    </div>
                  </div>
                  <div tw='flex flex-col items-end gap-3'>
                    <BodyText text={`– ${item?.amount}$`} twStyle={tw`whitespace-nowrap`} />
                    {item?.commission && <SubBody text={`комисся ${item?.commission}`} twStyle={tw`text-secondary`} />}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default TransactionList;

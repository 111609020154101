export const info_landing = {
  header: {
    main: 'Главная',
    deposit: 'Депозитная карта',
    invest: 'Карта инвестора',
    cards: 'Карты',
    prepaid: 'Prepaid карты',
    my_documents: 'Мои документы',
    status: 'Статус заявки'
  },
  banner: {
    title: 'Карты Freedom Bank для иностранных граждан',
    subtitle: 'Мультивалютные карты с полноценными банковскими счётами и IBAN, карты инвестора и предоплаченные карты'
  },
  card_types: {
    deposit: {
      title: 'Депозитная карта',
      subtitle: 'Вознаграждение до 0,1%  в тенге ',
      subtitle2: 'Привязана к депозитным счетам'
    },
    invest: {
      title: 'Карта инвестора',
      subtitle: 'Вознаграждение 3% в долларах',
      subtitle2: 'Привязана к брокерскому счёту'
    },
    prepaid: { title: 'Prepaid карты', subtitle: 'Номиналом 50, 100 и 200 $' },
    soon: 'скоро',
    card_1: {
      title: 'Выпуск карты ',
      subtitle: 'за 10 минут в отделении'
    },
    card_2: {
      title: 'Конвертации и переводы',
      subtitle: 'внутри банка без комиссий'
    },
    card_3: {
      title: 'Свобода движения денег',
      subtitle: 'Пополняйте и выводите деньги любым удобным способом'
    },
    card_4: {
      title: 'Счета и вклады защищены'
    },
    card_5: {
      title: 'Легко платить',
      subtitle: 'по всему миру и в интернете'
    },
    card_6: {
      title: 'Вознаграждение на остаток',
      subtitle: 'до 0,1% в тенге'
    },
    invest_cards: {
      card_1: {
        title: 'Выпуск карты онлайн',
        subtitle: 'за 5 минут без посредников'
      },
      card_2: {
        title: 'Конвертации и переводы',
        subtitle: 'внутри банка без комиссий'
      },
      card_3: {
        title: 'Удобно инвестировать',
        subtitle: 'Карта привязана к счёту во Freedom Finance Global PLC'
      },
      card_4: {
        title: 'Свобода движения денег',
        subtitle: 'Доступны разные способы пополнения и вывода денег'
      },
      card_5: {
        title: 'Легко платить',
        subtitle: 'по всему миру и в интернете'
      },
      card_6: {
        title: 'Вознаграждение на остаток',
        subtitle: '3% годовых в долларах'
      }
    },
    button: 'Подробнее о карте'
  },
  info_block_1: {
    title: 'Казахстан — №1 в рейтинге инвестиционной привлекательности среди стран Центральной Азии',
    image_caption: 'Первое место в рейтинге инвестиционной привлекательности в Центральной Азии (Ernst&Young, 2022)',
    block_1: {
      title: '25 место',
      caption: 'в индексе лёгкости ведения бизнеса Doing Business (2020)'
    },
    block_2: {
      title: '65 место',
      caption: 'в рейтинге устойчивого развития (2022)'
    },
    block_3: {
      title: 'BBB, прогноз стабильный',
      caption: 'Страновой рейтинг Fitch'
    },
    block_4: {
      title: 'Bаа2, прогноз стабильный',
      caption: 'Страновой рейтинг Moody’s'
    },
    subtitle:
      'По мнению зарубежных инвесторов и аналитиков, Казахстана лидирует в рейтинге благодаря: высокому потенциалу внутреннего рынка, нейтральной политике, правительственному плану восстановления экономики, инвестициям в науку и исследования, продвижению институтов развития финансовых рынков (например, МФЦА).'
  },
  info_block_2: {
    title: 'Freedom Bank — часть международного инвестиционного холдинга Freedom Holding Corp.',
    image_caption: 'Акции холдинга размещены на бирже Nasdaq',
    block_1: {
      title: '119 офисов',
      caption: 'в 15 странах мира'
    },
    block_2: {
      title: '14 лет',
      caption: 'на мировых финансовых рынках'
    },
    block_3: {
      title: '3,87 млрд $',
      caption: 'рыночная капитализация'
    },
    block_4: {
      title: '59 млрд $',
      caption: 'оборот ценных бумаг на клиентских счетах в 2019'
    },
    block_5: {
      title: '621 млн $',
      caption: 'собственный капитал'
    },
    block_6: {
      title: '310 000',
      caption: 'брокерских счетов'
    },
    block_7: {
      title: 'B-/B, прогноз стабильный',
      caption: 'Рейтинг холдинга от S&P Global Ratings'
    },
    actual_date: 'Данные актуальны на 30.09.2022'
  },
  info_block_3: {
    title: 'Freedom Bank — лидер по запуску цифровых продуктов в Казахстане',
    caption:
      'Наши клиенты получают ипотеку и автокредиты онлайн, без сбора документов. Мы — лидер по количеству выданных ипотек по государственной программе “7-20-25”.',
    subtitle1: 'Рейтинги',
    block_1: {
      title: 'B-/B-, прогноз позитивный',
      caption: 'Рейтинг банка от S&P Global Ratings'
    },
    block_2: {
      title: '11 место',
      caption: 'по активам среди 22 казахстанских банков'
    },
    block_3: {
      title: 'kzBB',
      caption: 'Национальный рейтинг'
    },
    subtitle2: 'Капитал и активы',
    block_4: {
      title: '69',
      caption: 'млрд тенге собственный капитал'
    },
    block_5: {
      title: '1 264',
      caption: 'млрд тенге совокупных активов'
    },
    block_6: {
      title: '302',
      caption: 'млрд тенге кредитный портфель'
    },
    block_7: {
      title: '604',
      caption: 'млрд тенге депозитный портфель'
    },
    subtitle3: 'Показатели роста и прибыль в 2022',
    block_8: {
      title: '5,6×',
      caption: 'рост депозитного портфеля'
    },
    block_9: {
      title: '14,9×',
      caption: 'рост кредитного портфеля'
    },
    block_10: {
      title: '3×',
      caption: 'рост активов и обязательств'
    },
    block_11: {
      title: '19',
      caption: 'млрд тенге чистая прибыль'
    },
    actual_date: 'По данным nationalbank.kz на 01.01.2023',
    image_title: 'Первое место в мире по цифровизации ипотеки',
    image_caption: 'Мы оцифровали оформление ипотеки на 100%'
  },
  footer: {
    free_call: 'бесплатный звонок по РК ',
    free_call_ru: 'бесплатный звонок по РФ',
    whatsapp: 'telegram-bot для клиентов за рубежом',
    license: '© 2024, АО «Фридом Банк Казахстан». Лицензия АРРФР № 1.1.108 от 25.06.2024'
  }
};
